html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  line-height: normal;
  color: $textDefault;
  background-color: $white;
  &.new {
    background: url("../img/main_bg.jpg") center / 100% auto no-repeat;
  }
}

.container-fluid {
  max-width: 1260px;
  padding: 0 72px;
  @media (max-width: $screen-sm) {
    padding: 0 16px;
  }
}

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

.content {
  position: relative;
  flex: 1 0 auto;
}

.btn,input[type='button'] {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 17px 35px;
  width: auto;
  color: $textDefault;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  line-height: 22px;
  font-weight: bold;
  outline: none !important;
  cursor: pointer;
  user-select: none;
  border: 2px solid $black;
  background-color: transparent;
  border-radius: 10px;
  &:hover {
    color: rgba(31,34,41,.7);
    border-color: rgba(31,34,41,.7);
  }
  &:focus {
    box-shadow: none !important;
  }
  &.small {
    padding: 11px 45px;
  }
  &.btn-dark {
    color: $white;
    border-color: $textDefault;
    background-color: $textDefault;
    &:hover {
      background-color: lighten($textDefault, 10);
      border-color: lighten($textDefault, 10);
    }
  }
  &.btn-white {
    color: $black;
    border-color: $white;
    background-color: $white;
    &.transparent {
      color: $white;
      background-color: transparent;
    }
  }
  &.btn-invert {
    color: $white;
    border: 2px solid $white;
    &:hover {
      color: #8f9399;
      border-color: #8f9399;
    }
  }
  &.wide {
    display: block;
    text-align: center;
  }
}

.bg-white-wrap {
  background-color: $white;
}
textarea {
  padding: 15px 16px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #D5D8DD;
  border-radius: 4px;
}

.breadcrumbs {
  font-size: 16px;
  color: #989898;
  cursor: default;
  a {
    color: #989898;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .breadcrumb-sep {
    margin: 0 5px;
  }
}

.tab-wrap {
  .tab-content {
    display: none;
    &.active {
      display: block;
    }
  }
}

.second-tab-wrap {
  .tab-pane {
    display: none;
    &.active {
      display: block;
    }
  }
}

.mb-35 {
  margin-bottom: 35px;
}
.mb-45 {
  margin-bottom: 45px;
}

// Checkbox, Radiobutton
.checkbox, .radio {
  position: relative;
  input[type="checkbox"],
  input[type="radio"] {
    display: none;
  }
  label {
    margin: 0;
    position: relative;
    display: block;
    padding: 0 0 0 36px;
    min-height: 24px;
    line-height: 24px;
    color: $textDefault;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
  input[type="checkbox"], input[type="radio"] {
    & + label {
      &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        background: transparent;
        border-radius: 2px;
      }
      &::before {
        border: 2px solid #A2ABB4;
      }
      &::after {
        display: none;
      }
    }
    &:checked {
      & + label {
        &:after {
          display: block;
        }
      }
    }
  }
  input[type="checkbox"] {
    & + label {
      &::after {
        width: 20px;
        height: 20px;
        background: url(../img/svg/checkmark.svg) no-repeat 50% 50%;
      }
    }
    &:checked {
      & + label {
        &:after {
          display: block;
          border-color: $textDefault;
          background-color: $white;
        }
      }
    }
  }
  input[type="radio"] {
    & + label {
      &::before, &::after {
        border-radius: 50%;
      }
      &::after {
        display: none;
        top: 8px;
        left: 8px;
        width: 8px;
        height: 8px;
        background-color: $white;
        border-radius: 50%;
      }
    }
    &:checked {
      & + label {
        &:before {
          display: block;
          border-color: $textDefault;
          background-color: $textDefault;
        }
      }
    }
  }
  &.disabled {
    input[type="checkbox"], input[type="radio"] {
      & + label {
        &::before {
          background-color: $gray;
          border-color: $gray;
        }
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }
  &.complex {
    label {
      padding: 20px 45px 20px 20px;
      color: $textDefault;
      border: 1px solid #E5E5E5;
      border-radius: 4px;
      .title {
        margin-bottom: 12px;
        display: block;
        font-size: 18px;
        font-weight: 700;
      }
      .text {
        display: block;
        font-size: 16px;
        font-weight: 500;
      }
    }
    input[type="radio"] {
      & + label {
        &::before {
          top: 20px;
          right: 20px;
          left:inherit;
        }
        &::after {
          top: 20px;
          right: 20px;
          left:inherit;
          margin: 6px 6px 0 0;
        }
      }
      &:checked {
        & + label {
          border: 1px solid $white;
        }
      }
    }
  }
  &.white {
    input[type="radio"] {
      & + label {
        &::before, &::after {
          border-radius: 50%;
        }
        &:before {
          border-color: $white;
        }
        &::after {
          top: 8px;
          left: 8px;
          width: 8px;
          height: 8px;
          background-color: $black;
          border-radius: 50%;
        }
      }
      &:checked {
        & + label {
          &:before {
            display: block;
            border-color: $white;
            background-color: $white;
          }
        }
      }
    }
  }
}

input {
  padding: 13px 16px;
  width: 100%;
  color: $textDefault;
  line-height: 1.25;
  border: 1px solid #D5D8DD;
  border-radius: 4px;
  outline: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }
}

textarea {
  padding: 15px 16px;
  width: 100%;
  color: $textDefault;
  line-height: normal;
  border: 1px solid #D5D8DD;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  @include placeholder(#BABDC4);
  &:focus {
    outline: none;
  }
}

.form-group {
  label {
    margin-bottom: 5px;
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: $textDefault;
  }
}

.predictions-wrap-express {
  .title-main {
    margin-bottom: 12px;
  }
  .text-main {
    margin-bottom: 24px;
    font-size: 18px;
    color: #626469;
  }
  .left-block {
    margin: -15px 90px 0 0;
    width: 45%;
    &.card-list {
      padding-bottom: 0;
      gap: 30px 30px;
    }
    .card-block {
      width: calc(25% - 30px);
      border-radius: 8px;
      cursor: pointer;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      @include aspect-ratio(9, 13.5, 'inner-wrap');
      &.active {
        border-color: $black;
      }
    }
  }

  .info-block {
    min-width: 250px;
    .custom-select-block {
      margin-bottom: 26px;
    }
  }
  @media (max-width: $screen-sm) {
    .text-main {
      font-size: 14px;
    }
    .left-block {
      margin: 0 0 36px;
      width: inherit;
    }

    .info-block {
      .custom-select-block {
        margin-bottom: 16px;
      }
    }
  }
}

.custom-input-block {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  label {
    margin-bottom: 0 !important;
    position: absolute;
    top: 21px;
    left: 18px;
    font-size: 16px;
    color: #798795;
  }
  .suggest-block {
    display: none;
    &.active {
      display: block;
    }
  }
  .suggest-list {
    margin-bottom: 12px;
    width: 100%;
    background-color: $white;
    border: 2px solid $textDefault;
    z-index: 3;
    .suggest {
      padding: 12px 17px;
      cursor: pointer;
      &.active {
        background-color: rgba(148, 148, 148, 0.2);
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  input {
    font-size: 16px;
    padding: 29px 18px 9px;
    color: $textDefault;
    border: 1px solid #798795;
    border-radius: 8px;
  }
  .error-message {
    display: none;
  }
  &.white {
    input {
      background-color: $white;
      border-color: #D3D9DF;
    }
  }
  &.check {
    &:after {
      position: absolute;
      right: 12px;
      bottom: 10px;
      content: "\e906";
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
    }
  }
  .error &,
  &.error {
    &:before{
      position: absolute;
      top: 18px;
      right: 16px;
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background: url('/static/img/svg/iconsError24.svg') center / 100% 100% no-repeat;
      background-size: cover;
      z-index: 2;
    }
    input {
      color: $red !important;
      border-color: $red !important;
      box-shadow: 0 0 4px rgba(255, 117, 140, 0.5);
      @include placeholder($red);
    }
    .error-message {
      display: block;
      padding-top: 10px;
      color: $red;
    }
  }
  &.active {
    label {
      top: 9px;
      color: $textDefault;
      font-size: 13px;
    }
  }
  &.disabled {
    pointer-events: none;
  }
}

.filter-block {
  position: absolute;
  top: 10px;
  right: 0;
  .filter-toggle {
    padding: 9px 12px;
    cursor: pointer;
    img {
      margin-right: 3px;
      display: block;
    }
  }
  .filter-list {
    display: none;
    position: absolute;
    top: 39px;
    width: 100%;
    z-index: 1;
    background-color: $white;
    .element {
      padding: 7px 10px;
      cursor: pointer;
      &:hover {
        background-color: darken($white, 30);
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  &.active {
    .filter-list {
      display: block;
    }
  }
  @media (max-width: $screen-sm) {
    top: 5px;
  }
}

.bg-gray {
  background-color: #F5F5FA;
}
.bg-violet {
  background-color: #BD87F8;
}
.text-violet {
  color: #BD87F8;
}
.text-green {
  color: #41A674;
}
.text-yellow {
  color: #FCCB37;
}
.text-gray {
  color: #8F9399;
}
.text-gray-400 {
  color: $gray400;
}

.tab-list,
.list-group {
  display: flex;
  border-bottom: 1px solid #D1DBD6;
  .tab {
    position: relative;
    padding: 8px 12px;
    text-align: center;
    flex-grow: 1;
    color: #8F9399;
    cursor: pointer;
    text-decoration: none;
    &.active {
      color: $textDefault;
      &:after {
        content:'';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background-color: $textDefault;
      }
    }
  }
  &.gray {
    padding: 30px 15px;
    border-bottom: none;
    background-color: #F0F1F2;
    .tab {
      flex-grow: 0;
      padding: 0;
      margin-right: 37px;
      color: #8F9399;
      &.active {
        color: $textDefault;
        &:after {
          display: none;
        }
      }
    }
  }
  &.small {
    .tab {
      flex-grow: 0;
    }
  }
}

.toggle-link-default {
  color: $textDefault;
  text-decoration: none;
  .icon {
    display: inline-block;
    vertical-align: middle;
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    &:before {
      content: "\e903";
    }
  }
  &.btn-dark {
    color: $white;
  }
  &:focus {
    color: $textDefault;
  }
  .text {
    &.active {
      display: none;
    }
  }
  &.collapsed {
    .text {
      display: none;
      &.active {
        display: inline;
      }
    }
    .icon {
      &:before {
        content: "\e906";
      }
    }
  }
}

.tag-block-default {
  margin: 0 20px 15px 0;
  display: flex;
  align-items: center;
  border-radius: 12px;
  font-size: 12px;
  .icon {
    margin-right: 10px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    &.icon-inst {
      position: relative;
      overflow: hidden;
      &:after {
        content:'';
        position: absolute;
        top: 2px;
        left: 2px;
        right: 2px;
        bottom: 2px;
        background-color: $black;
        border-radius: 50%;
        z-index: -1;
      }
    }
    &.icon-www {
      font-size: 14px;
      color: $white;
      background-color: #C185FF;
    }
    &.icon-fb {
      font-size: 14px;
      color: $white;
      background-color: #3B5998;
    }
  }
  .name {
    margin-bottom: 0;
    font-size: 12px;
  }
  &.activity {
    font-size: 14px;
    background-color: transparent;
  }
}

.custom-popover {
  max-width: 350px;
  width: 100%;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.14);
  border: none;
  border-radius: 0;
  .title-block {
    margin-bottom: 18px;
    .title {
      margin-right: 15px;
      font-family: 'Alegreya SC', sans-serif;
      font-size: 18px;
      font-weight: bold;
    }
    .close {
      font-size: 19px;
      cursor: pointer;
    }
  }
  .text {
    color: #626469;
  }
  .pos {
    color: #979797;
  }
}

.predictions-page-wrap {
  padding: 70px 0 90px;
  .title-main {
    margin-bottom: 18px;
  }
  .text-main {
    margin-bottom: 30px;
    font-size: 18px;
  }
  .tab-list {
    margin-bottom: 40px;
    margin-left: -36px;
    border: none;
  }
  .tab {
    margin-left: 36px;
    width: calc(25% - 36px);
    background: $white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14);
    border: 2px solid $white;
    border-radius: 8px;
    &.active {
      border-color: $black;
    }
    .icon {
      margin-right: 20px;
      flex-shrink: 0;
      width: 70px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F5F5FA;
      border-radius: 50%;
      background-position: center;
      background-repeat: no-repeat;
      &.family {
        background-image: url('/static/img/svg/001-first-aid-kit.svg');
      }
      &.love {
        background-image: url('/static/img/svg/002-family.svg');
      }
      &.business {
        background-image: url('/static/img/svg/003-hand-shake.svg');
      }
      &.health {
        background-image: url('/static/img/svg/004-heart.svg');
      }
    }
    .text {
      font-family: 'Open Sans', sans-serif;
      font-size: 20px;
      color: $textDefault;
      font-weight: bold;
    }
  }
  .text {
    font-size: 14px;
  }
  .card-list {
    margin-left: -26px;
    position: relative;
    .btn {
      margin: -24px 0 0 -56px;
      position: absolute;
      top: 50%;
      right: 85px;
    }
  }
  .card-block {
    margin-left: 26px;
    width: calc(25% - 26px);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    border: 2px solid $white;
    @include aspect-ratio(9, 12.8, 'inner-wrap');
    &.active {
      border-color: #C185FF;
    }
  }
  .taro-predictions-wrap {
    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
    .info-block {
      padding-top: 30px;
      .title {
        margin-bottom: 12px;
        font-size: 22px;
        font-weight: bold;
        color: #474A51;
      }
      .text {
        margin-bottom: 42px;
        font-size: 18px;
        color: $black;
      }
    }
  }
  @include card-reveal-block();
  @media (max-width: $screen-sm) {
    padding: 30px 0 55px;
    .tab-list {
      margin-bottom: 2px;
      margin-left: -8px;
      border: none;
    }
    .tab {
      margin: 0 0 8px 8px;
      width: calc(50% - 8px);
      .icon {
        margin-right: 8px;
        width: 40px;
        height: 40px;
        background-size: calc(100% - 14px) calc(100% - 14px);
      }
      .text {
        font-size: 16px;
      }
    }
    .card-list {
      padding-bottom: 88px;
      margin-left: -10px;
      .btn {
        margin: -24px 0 0 -56px;
        position: absolute;
        top: inherit;
        bottom: 0;
        right: inherit;
        left: 50%;
      }
    }
    .card-block {
      margin-left: 10px;
      width: calc(33.33% - 10px);
    }
    .taro-predictions-wrap {
      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
      }
      .info-block {
        padding-top: 30px;
        .title {
          margin-bottom: 16px;
          font-size: 18px;
        }
        .text {
          margin-bottom: 30px;
          font-size: 15px;
          color: $textDefault;
        }
      }
    }
  }
}

.toggle-block-default {
  .text {
    overflow: hidden;
  }
  .btn-toggle {
    text-decoration: none;
    cursor: pointer;
    .icon {
      display: inline-block;
      vertical-align: middle;
      font-family: 'icomoon' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      color: $textDefault;
      &:before {
        content: "\e906";
      }
    }
    .text {
      display: inline;
      color: $textDefault;
      &.active {
        display: none;
      }
    }
    &.active {
      .text {
        display: none;
        &.active {
          display: inline;
        }
      }
      .icon {
        &:before {
          content: "\e903";
        }
      }
    }
  }
  &.show {
    .text {
      height: auto !important;
    }
  }
}

.carousel {
  .control {
    font-weight: bold;
    color: $textDefault;
    &.left {
      .icon {
        margin-right: 12px;
      }
    }
    &.right {
      .icon {
        margin-left: 12px;
      }
    }
    .icon {
      font-size: 20px;
    }
  }
}

.custom-select-block {
  position: relative;
  .select-label {
    position: absolute;
    top: 19px;
    left: 16px;
    font-size: 16px;
    color: #65707B;
    z-index: 1;
  }
  .select-input-block {
    position: relative;
    padding: 29px 16px 9px 16px;
    color: $textDefault;
    font-size: 16px;
    height: 60px;
    background: $white;
    border: 1px solid #D3D9DF;
    cursor: pointer;
    border-radius: 8px;
    &:after {
      position: absolute;
      right: 16px;
      bottom: 18px;
      font-size: 23px;
      content: "\e906";
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      color: #65707B;
    }
  }
  .select-list {
    padding: 16px;
    display: none;
    position: absolute;
    margin-top: -2px;
    width: 100%;
    color: $textDefault;
    background-color: $white;
    box-shadow: 0 50px 30px -40px rgba(31, 34, 41, 0.02), 0px 2px 8px rgba(31, 34, 41, 0.04), 0px 17px 40px rgba(31, 34, 41, 0.07);
    border-radius: 8px;
    .element {
      margin-bottom: 15px;
      padding: 7px 0 7px 15px;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        background-color: darken($white, 20%);
      }
    }
    &.checklist {
      padding: 8px 4px;
      box-shadow: 0 50px 30px -40px rgba(31, 34, 41, 0.02), 0px 2px 8px rgba(31, 34, 41, 0.04), 0px 17px 40px rgba(31, 34, 41, 0.07);
      border-radius: 8px;
      .checkbox {
        padding: 8px 12px;
        border-radius: 8px;
        label {
          margin-bottom: 0;
        }
        &:hover {
          background: #EBEDEF;
        }
      }
    }
  }
  .error-message {
    display: none;
  }
  &.active {
    z-index: 2;
    .select-list {
      display: block;
    }
  }
  &.selected {
    .select-label {
      top: 9px;
      left: 16px;
      display: block;
      font-size: 13px;
    }
  }
  .error &,
  &.error {
    .select-input-block {
      color: $red !important;
      border-color: $red !important;
      box-shadow: 0 0 4px rgba(255, 117, 140, 0.5);
      @include placeholder($red);
    }
    .error-message {
      display: block;
      padding-top: 10px;
      color: $red;
    }
  }
  &.black {

  }
}

.table {
  color: $textDefault;
  td {
    padding: 28px 9px 28px;
  }
}

.card-reveal {
  .img-block {
    margin-right: 90px;
    display: flex;
    min-width: 200px;
    align-items: center;
    justify-content: center;
    img {
      max-height: 350px;
    }
  }
}

.banner-block {
  &.crystals {
    color: $white;
    padding: 14px 0 20px;
    background: url('/static/img/promos/crystals/quest_big.png') center no-repeat $textDefault;
    .title {
      font-size: 30px;
      font-family: 'Alegreya SC', sans-serif;
      font-weight: bold;
    }
    .text {
      max-width: 870px;
      margin: 0 auto 12px;
    }
  }
}
