.profile-page-wrap {
  .user-profile-block {
    position: relative;
    color: $white;
    background: url('/static/img/bg-profile.png') center top / 100% auto no-repeat;
    .mob-head-title {
      margin: 0 0 5px;
      padding: 13px 16px;
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      background-color: #2f333b;
    }
    .top-block {
      color: $white;
      .user-profile-updating,
      .user-profile-saved {
        padding: 42px 90px 40px 0;
      }
    }
    .cog {
      position: absolute;
      top: 22px;
      right: 0;
      width: 31px;
      height: 31px;
      background: url('/static/img/svg/cog.svg') center / 100% 100% no-repeat;
      cursor: pointer;
    }
    .container-fluid {
      position: relative;
    }
    .user-name {
      margin-bottom: 18px;
      font-family: 'Alegreya SC', sans-serif;
      font-size: 32px;
      font-weight: bold;
    }
    .user-property {
      font-size: 18px;
      color: #C4C4C4;
      .value {
        color: $white;
      }
      &.crystals {
        color: $purple;
      }
      img {
        margin-left: 8px;
      }
    }
    .top-block {
      min-height: 277px;
      .avatar-block {
        position: relative;
        width: 177px;
        height: 177px;
        border-radius: 50%;
        background-position: center;
        background-size: 100% auto;
        .user-status {
          position: absolute;
          top: 12px;
          right: 19px;
          width: 23px;
          height: 23px;
          border: 2px solid $textDefault;
          background-color: #BDE02B;
          border-radius: 50%;
        }
      }
    }
    .avatar-choose-wrap {
      padding: 24px 0 0;
      .title-main {
        margin-bottom: 18px;
        font-size: 32px;
        font-weight: 500;
        font-family: 'Alegreya SC', sans-serif;
      }
      .text-main {
        margin-bottom: 26px;
        font-size: 16px;
      }
    }
    .avatar-list {
      margin-bottom: 54px;
      margin-left: -10px;
      .avatar-select {
        margin: 0 0 0 10px;
        position: relative;
        width: calc(25% - 12px);
        aspect-ratio: 1;
        border: 8px solid transparent;
        border-radius: 4px;
        background-size: 100% 100%;
        cursor: pointer;
        &.current {
          border-color: #BD87F8;
          &:before {
            content: '\e93c';
            position: absolute;
            top: -20px;
            right: -14px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'icomoon' !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            width: 26px;
            height: 26px;
            border: 2px solid $white;
            background-color: #BD87F8;
            border-radius: 50%;
          }
        }
      }
    }
    @media (max-width: $screen-sm) {
      .cog {
        top: 10px;
        right: 12px;
        width: 23px;
        height: 23px;
      }
      .user-name {
        margin-bottom: 8px;
        font-size: 18px;
      }
      .user-property {
        font-size: 14px;
      }
      .top-block {
        min-height: auto;
        .avatar-block {
          width: 88px;
          height: 88px;
          .user-status {
            width: 12px;
            height: 12px;
            border-width: 1px;
          }
        }
      }
      .avatar-choose-wrap {
        padding: 24px 0;
        .title-main {
          margin-bottom: 12px;
          font-size: 18px;
        }
        .text-main {
          margin-bottom: 25px;
          font-size: 14px;
        }
      }
      .avatar-list {
        margin-bottom: 20px;
        padding: 0;
      }
      .avatar-list-inner {
        padding: 14px;
      }
    }
  }
  .user-profile-categories {
    padding-top: 35px;
    background-color: $white;
    .category-list {
      margin-left: -32px;
    }
    .category-block {
      margin: 0 0 36px 36px;
      position: relative;
      padding: 25px 80px 14px 30px;
      width: calc(50% - 36px);
      min-height: 190px;
      border-radius: 4px;
      background-color: $textDefault;
      .title {
        margin-bottom: 10px;
        position: relative;
        font-family: 'Alegreya SC', sans-serif;
        font-size: 24px;
        font-weight: bold;
        color: $white;
        z-index: 1;
      }
      .text {
        position: relative;
        font-size: 14px;
        color: #F5F5FA;
        z-index: 1;
        img {
          width: 15px;
        }
      }
      .btn {
        z-index: 1;
      }
      .img {
        position: absolute;
      }
      .quiz-block {
        position: relative;
        margin-bottom: 10px;
        color: $white;
        z-index: 1;
        .title {
          font-size: 16px;
        }
        .place {
          margin-bottom: 12px;
          font-family: 'Alegreya SC', sans-serif;
          font-weight: bold;
          .digit {
            margin-right: 10px;
            font-size: 36px;
            line-height: 36px;
          }
          .text {
            font-size: 16px;
          }
        }
        a {
          position: relative;
          color: $white;
          z-index: 1;
        }
      }
      .balance-block {
        position: relative;
        color: $white;
        z-index: 1;
        a {
          color: $white;
        }
        .text {
          margin-bottom: 6px;
          font-weight: bold;
        }
        .sum {
          margin-right: 5px;
          font-family: 'Alegreya SC', sans-serif;
          font-size: 36px;
          line-height: 30px;
          font-weight: bold;
        }
        .mineral {
          width: 29px;
          height: 29px;
          background: url('/static/img/mineral.png') no-repeat;
        }
      }
      &.one {
        &:before {
          content:'';
          position: absolute;
          top: 3px;
          left: 50%;
          width: 66px;
          height: 92px;
          background: url('/static/img/profile1-bg.png') center / 100% 100% no-repeat;
        }
        .crystal {
          flex-shrink: 0;
        }
        .img {
          top: 16px;
          right: -23px;
          width: 162px;
          height: 162px;
          background: url('/static/img/svg/chest.svg') center / cover no-repeat;
        }
      }
      &.two {
        &:before {
          content:'';
          position: absolute;
          bottom: 3px;
          left: 44%;
          width: 89px;
          height: 102px;
          background: url('/static/img/profile2-bg.png') center / 100% 100% no-repeat;
        }
        background: linear-gradient(91.08deg, #551993 0%, #C185FF 100%);
        .img {
          bottom: 0;
          right: -29px;
          width: 136px;
          height: 111px;
          background: url('/static/img/profile-dragon.png') no-repeat;
        }
      }
      &.play {
        background: $textDefault;
        &:before {
          top: 20px;
          bottom: inherit;
        }
        &.stage-one {
          .img {
            bottom: 0;
            right: 33px;
            width: 123px;
            height: 155px;
            background: url('/static/img/svg/dragons/egg.svg') center no-repeat;
          }
        }
        &.stage-two {
          .img {
            bottom: 0;
            right: 30px;
            width: 120px;
            height: 177px;
            background: url('/static/img/svg/dragons/egg2.svg') center no-repeat;
          }
        }
        &.stage-three {
          .img {
            bottom: 0;
            right: -10px;
            width: 185px;
            height: 206px;
            background: url('/static/img/svg/dragons/dragon1.svg') center no-repeat;
          }
        }
        &.stage-four {
          .img {
            bottom: inherit;
            top: 23px;
            right: -7px;
            width: 285px;
            height: 200px;
            background: url('/static/img/svg/dragons/dragon2.svg') center no-repeat;
          }
        }
        &.stage-five {
          .img {
            right: 4px;
            bottom: 0;
            width: 230px;
            height: 178px;
            background: url('/static/img/svg/dragons/dragon3.svg') center no-repeat;
          }
        }
      }
      &.three {
        &:before {
          content: '';
          position: absolute;
          top: 9px;
          left: 45%;
          width: 105px;
          height: 102px;
          background: url('/static/img/profile3-bg.png') no-repeat;
        }
        &.unauth {
          margin-bottom: 10px;
          .title {
            margin-bottom: 60px;
          }
          .quiz-block {
            display: none;
          }
        }
        .img {
          position: absolute;
          bottom: 18px;
          right: 9px;
          width: 167px;
          height: 148px;
          background: url('/static/img/profile-3-img.png') no-repeat;
        }
      }
      &.four {
        &:before,
        &:after {
          content: '';
          position: absolute;
        }
        &:before {
          top: -18px;
          left: 111px;
          width: 125px;
          height: 125px;
          background: url('/static/img/profile4-bg1.png') no-repeat;
        }
        &:after {
          top: 75px;
          right: 91px;
          width: 26px;
          height: 22px;
          background: url('/static/img/profile4-bg2.png') no-repeat;
        }
        .title-block {
          .title {
            margin-right: 80px;
          }
        }
        .img {
          position: absolute;
          bottom: 10px;
          right: 16px;
          width: 50px;
          height: 69px;
          background: url('/static/img/profile4-bg3.png') no-repeat;
        }
      }
      &.dragons {
        background: linear-gradient(91.08deg, #551993 0%, #C185FF 100%);
        .title {
          margin: 0 28px 28px 0;
        }
        a {
          position: relative;
          color: $white;
          z-index: 1;
          &:hover {
            text-decoration: none;
          }
        }
        .img {
          position: absolute;
          right: -25px;
          bottom: 0;
          width: 100%;
          aspect-ratio: 16/4.5;
          background: url('/static/img/profile-dragon-new.png') center bottom / contain no-repeat;
        }
        .icon-info1 {
          position: absolute;
          top: 11px;
          right: 13px;
          font-size: 24px;
          text-decoration: none;
          cursor: pointer;
        }
      }
      &.crystals {
        .title {
          margin: 0 28px 28px 0;
        }
        a {
          color: $white;
        }
        .icon-info1 {
          position: absolute;
          top: 11px;
          right: 13px;
          font-size: 24px;
          text-decoration: none;
          cursor: pointer;
        }
        .img {
          position: absolute;
          right: 24px;
          bottom: 7px;
          width: 274px;
          aspect-ratio: 16/7.6;
          background: url('/static/img/promos/crystals/quest2.png') center bottom / contain no-repeat;
        }
      }
    }
    @media(max-width: $screen-sm) {
      .category-block {
        margin-bottom: 16px;
        padding: 14px 10px 18px;
        width: 100%;
        .title {
          font-size: 20px;
        }
        .text {
          font-size: 14px;
        }
        &.one {
          &:before {
            top: 38px;
            left: 38%;
            width: 36px;
            height: 52px;
            background: url('/static/img/profile1-bg.png') center / 100% 100% no-repeat;
          }
          .img {
            top: inherit;
            bottom: 0;
            right: -3px;
            width: 120px;
            height: 120px;
            background-size: 100% 100%;
          }
        }
        &.two {
          padding-bottom: 75px;
          .img {
            bottom: 0;
            right: -10px;
            width: 136px;
            height: 111px;
            background: url('/static/img/profile-dragon.png') no-repeat;
          }
          .img2 {
            position: absolute;
            bottom: 0;
            left: -10px;
            width: 151px;
            height: 76px;
            background: url('/static/img/profile-dragon2.png') no-repeat;
          }
        }
        &.three {
          .img {
            width: 124px;
            height: 110px;
            background-size: 100% 100%;
          }
        }
        &.play {
          &.stage-one {
            .img {
              bottom: 0;
              right: 33px;
              width: 123px;
              height: 155px;
              background: url('/static/img/svg/dragons/egg.svg') center no-repeat;
            }
          }
          &.stage-two {
            .img {
              bottom: 0;
              right: 30px;
              width: 120px;
              height: 177px;
              background: url('/static/img/svg/dragons/egg2.svg') center no-repeat;
            }
          }
          &.stage-three {
            .img {
              bottom: 0;
              right: -10px;
              width: 185px;
              height: 206px;
              background: url('/static/img/svg/dragons/dragon1.svg') center no-repeat;
            }
          }
          &.stage-four {
            .img {
              bottom: inherit;
              top: 23px;
              right: -7px;
              width: 285px;
              height: 200px;
              background: url('/static/img/svg/dragons/dragon2.svg') center no-repeat;
            }
          }
          &.stage-five {
            .img {
              right: 4px;
              bottom: 0;
              width: 160px;
              height: 125px;
              background: url('/static/img/svg/dragons/dragon3.svg') center no-repeat;
            }
          }
        }
        &.dragons {
          .img {
            position: absolute;
            right: -16px;
            bottom: 0;
            width: 100%;
            height: auto;
            background: url('/static/img/profile-dragons-new-mobile.png') center / contain no-repeat;
          }
        }
        &.crystals {
          &:before {
            content:'';
            display: block;
            position: absolute;
            top: 10px;
            left: 40%;
            width: 89px;
            height: 102px;
            background: url('/static/img/profile2-bg.png') center / 100% 100% no-repeat;
          }
          .img {
            position: absolute;
            right: 11px;
            bottom: 9px;
            width: 190px;
            aspect-ratio: 16/10.9;
            background: url('/static/img/promos/crystals/quest_mobile.png') center / contain no-repeat;
          }
        }
      }
    }
  }
  .magic-powers-wrap {
    background-color: $white;
    .title-main {
      margin-bottom: 18px;
      font-size: 32px;
      font-family: 'Alegreya SC', sans-serif;
      font-weight: 500;
    }
    .text-main {
      margin-bottom: 32px;
      font-size: 18px;
      color: #626469;
    }
    .promo-block {
      margin-bottom: 20px;
      position: relative;
      padding: 22px 260px 26px 30px;
      color: $white;
      background: linear-gradient(90.75deg, #1F2829 1.19%, #6C30AA 95.95%);
      border-radius: 4px;
      .title {
        margin-bottom: 8px;
        font-size: 30px;
        font-weight: bold;
      }
      .text {
        font-size: 23px;
      }
      .cards {
        position: absolute;
        top: -18px;
        right: 55px;
        width: 206px;
        height: 146px;
        background: url('/static/img/card-question.png') center / contain no-repeat;
      }
    }
    .pick-card-block {
      padding-bottom: 40px;
      .card-list {
        margin-left: -36px;
      }
      .title {
        margin-bottom: 40px;
        font-size: 28px;
        font-weight: bold;
      }
      .card-block {
        margin: 0 0 40px 36px;
        position: relative;
        color: $black;
        font-size: 20px;
        font-weight: bold;
        width: calc(33.3% - 36px);
        border: 1px solid transparent;
        .text-choose {
          font-weight: normal;
        }
        .img-block {
          margin-bottom: 18px;
          width: 100%;
          border-radius: 8px;
          background: #F5F5FA no-repeat center / 90% auto;
          // background-size: 50% auto;
          @include aspect-ratio(1,1.1, 'inner-wrap');
          .inner-wrap {
            &:before,
            &:after {
              content:'';
              position: absolute;
              width: 37px;
              height: 37px;
            }
            &:before {
              top: 16px;
              left: 16px;
              background: url('/static/img/svg/profile-cards/stars.svg');
            }
            &:after {
              right: 16px;
              bottom: 16px;
              background: url('/static/img/svg/profile-cards/magic-ball.svg');
            }
          }
        }
        &.back {
          .img-block {
            background-size: 100% 100%;
            .inner-wrap {
              &:before,
              &:after {
                display: none;
              }
            }
          }
        }
        &.selected {
          .img-block {
            border: 1px solid
          }
        }
      }
      .points-block {
        padding: 35px 40px 35px 30px;
        background: $white;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.14);
        border-radius: 4px;
        .total {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
    @media (max-width: $screen-sm) {
      .title-main {
        margin-bottom: 18px;
        font-size: 28px;
      }
      .text-main {
        font-size: 14px;
        margin-bottom: 20px;
      }
      .promo-block {
        margin-right: 10px;
        padding: 15px 10px;
        .title {
          font-size: 18px;
        }
        .text {
          font-size: 14px;
        }
        .cards {
          top: -20px;
          right: -20px;
          width: 66px;
          height: 46px;
        }
      }
      .pick-card-block {
        .title {
          margin-bottom: 18px;
          font-size: 18px;
        }
        .card-list {
          margin-left: -6px;
        }
        .card-block {
          margin: 0 0 30px 6px;
          width: calc(33.3% - 6px);
          font-size: 13px;
          .img-block {
            margin-bottom: 8px;
          }

        }
        .points-block {
          padding: 15px 35px 40px;
          .total {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
  .my-notes-wrap {
    padding-bottom: 56px;
    .title-block {
      margin-bottom: 30px;
      a {
        margin-top: 10px;
        font-size: 14px;
        color: $textDefault;
      }
    }
    .title-main {
      margin-right: 22px;
      font-size: 32px;
      font-weight: 500;
    }
    .carousel-item {
      padding: 10px 8px 5px;
      .notes-list {
        margin-left: 0;
      }
      .note-block {
        width: calc(25% - 27px);
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .notes-list {
      margin-left: -36px;
    }
    .note-block {
      margin: 0 36px 20px 0;
      position: relative;
      padding: 15px 10px 10px;
      width: calc(25% - 36px);
      min-height: 110px;
      background: $white;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.14);
      border-radius: 4px;
      .close {
        position: absolute;
        top: -8px;
        right: -10px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        background: url('/static/img/svg/iconClear24.svg') center no-repeat;
      }
      .date {
        margin-bottom: 6px;
        color: #979797;
        font-size: 14px;
      }
      .edit-text {
        color: $textDefault;
      }
      .toggle-block {
        .edit-text {
          display: block;
        }
        .edit-block {
          display: none;
        }
        &.show {
          .edit-text {
            display: none;
          }
          .edit-block {
            display: block;
          }
          .icon-edit {
            display: none;
          }
        }
        .icon-edit {
          position: absolute;
          right: 4px;
          bottom: 5px;
          font-size: 24px;
        }
      }
      .add-link {
        font-size: 16px;
        color: $textDefault;
        text-decoration: none;
        .icon-add {
          margin-right: 12px;
          font-size: 24px;
        }
      }
    }
    .control-block {
      .control {
        cursor: pointer;
      }
    }
    @media (max-width: $screen-sm) {
      .notes-list {
        margin-left: 0;
      }
      .note-block {
        margin-left: 0;
        width: 100%;
      }
      .carousel-item {
        .note-block {
          width: 100%;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  .library-page-wrap {
    .books-wrap {
      padding: 0 0 20px;
      .title {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .my-predictions-wrap {
    background-color: $white;
    .title-main {
      margin-bottom: 45px;
      font-family: "Alegreya SC", sans-serif;
      font-size: 32px;
      font-weight: 500;
    }
    .select-wrap {
      margin-bottom: 75px;
    }
    .card-reveal {
      .gray {
        color: #979797;
      }
      .img-block {
        flex-shrink: 0;
        margin-right: 102px;
        .title {
          font-size: 18px;
        }
      }
      .info-block {
        padding-top: 25px;

        .title {
          font-size: 20px;
        }
        a {
          color: $black;
        }
      }
    }
    .control-block {
      @include control-block(4px);
    }
    @media (max-width: $screen-sm) {
      .select-wrap {
        margin-bottom: 28px;
      }
      .card-reveal {
        img {
          margin-right: 18px;
          width: 120px;
        }
        .info-block {
          padding-top: 15px;
        }
      }
    }
  }
  .books-wrap {
    .books-list {
      overflow-x: auto;
    }
    @media (max-width: $screen-sm) {
      .book-block {
        margin-right: 20px;
        width: 55%;
        .left-block {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
  .mandala-wrap {
    .title-main {
      margin-bottom: 30px;
      font-family: "Alegreya SC", sans-serif;
      font-size: 32px;
      font-weight: 500;
    }
    .select-wrap {
      margin-bottom: 75px;
    }
    .mandala-list {
      margin-left: -36px;
    }
    .mandala-block {
      margin: 0 0 30px 36px;
      width: calc(33.3% - 36px);
      flex-shrink: 0;
      .img-block {
        margin-bottom: 10px;
        border: 1px dashed #DADADA;
        border-radius: 4px;
        background-repeat: no-repeat;
        background-position: center;
        @include aspect-ratio(1,1, 'inner-wrap');
        &.one {
          background-color: #F6E0AE;
        }
        &.two {
          background-color: #C4E8DB;
        }
        &.three {
          background-color: #B2ECF1;
        }
        &.four {
          background-color: #CD9723;
        }
        &.five {
          background-color: #F6E5EA;
        }
        &.six {
          background-color: #F8C9AF;
        }
        &.seven {
          background-color: #FAFFDB;
        }
      }
      .title {
        margin-bottom: 20px;
        font-weight: bold;
      }
    }
    @media (max-width: $screen-sm) {
      .mandala-list {
        margin-left: 0;
        overflow-x: auto;
      }
      .mandala-block {
        margin-right: 10px;
        margin-left: 0;
        width: calc(100% - 40px);
        .img-block {
          background-size: auto 95%;
          background-position: center;
          @include aspect-ratio(16,9, 'inner-wrap');
        }
      }
    }
  }
  .logout-wrap {
    padding: 15px 0;
    background-color: #F5F5FA;
    a {
      color: $textDefault;
    }
  }
  .profile-chest-wrap {
    .chest-top {
      margin-bottom: 48px;
      background: url('/static/img/profile-chest-bg.png') $textDefault center no-repeat;

      .container-fluid {
        position: relative;
        min-height: 220px;
        color: $white;
      }

      .title {
        font-family: 'Alegreya SC', sans-serif;
        font-size: 42px;
        font-weight: bold;
      }
      .balance {
        font-size: 36px;
        line-height: 40px;
        font-weight: bold;
        span {
          margin-right: 8px;
        }
      }
      .crystal {
        width: 30px;
        height: 30px;
        background: url('/static/img/svg/mineral.svg') center / cover no-repeat;
      }
      .text {
        font-size: 16px;
        font-weight: bold;
      }

      .chest {
        position: absolute;
        top: 22px;
        right: 0;
        width: 191px;
        height: 191px;
        background: url('/static/img/svg/chest.svg') no-repeat;
        background-size: 100%;
      }
      @media (max-width: $screen-sm) {
        .container-fluid {
          position: relative;
          min-height: 80px;
        }
        .title {
          font-size: 22px;
        }
        .chest {
          top: 13px;
          right: 13px;
          width: 72px;
          height: 72px;
        }
        .balance {
          font-size: 16px;
        }
        .crystal {
          width: 13px;
          height: 13px;
        }
      }
    }
    .presents-list {
      margin-left: -40px;
    }
    .present-block {
      margin: 0 0 30px 40px;
      width: calc(33.3% - 40px);
      .present-img {
        margin-bottom: 25px;
        position: relative;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 4px;
        border: 1px dashed #DADADA;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        @include aspect-ratio(1, 1, 'inner-wrap');
        .icon-resize {
          position: absolute;
          right: 15px;
          bottom: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          background-color: #F2F3F4;
          border-radius: 50%;
          font-size: 18px;
          color: $black;
          cursor: pointer;
        }
        &.mandala-one {
          background-color: #F6E0AE;
        }
        &.mandala-two {
          background-color: #C4E8DB;
        }
        &.mandala-three {
          background-color: #B2ECF1;
        }
        &.mandala-four {
          background-color: #CD9723;
        }
        &.mandala-five {
          background-color: #F6E5EA;
        }
        &.mandala-six {
          background-color: #F8C9AF;
        }
        &.mandala-seven {
          background-color: #FAFFDB;
        }
      }
      .title {
        margin-bottom: 45px;
        font-size: 22px;
        font-weight: bold;
      }
      .crystal {
        width: 13px;
        height: 13px;
        background: url('/static/img/svg/mineral-black.svg') center / cover no-repeat;
      }
    }
    @media (max-width: $screen-sm) {
      .presents-list {
        margin-left: 0;
      }
      .present-block {
        margin: 0 0 20px 0;
        width: 100%;
        .present-img {
          @include aspect-ratio(16, 9, 'inner-wrap');
        }
      }
    }
  }
  .my-predictions-wrap {
    padding-bottom: 55px;
    @media (max-width: $screen-sm) {
      padding-bottom: 30px;
    }
  }
  .empty-block {
    p {
      font-size: 20px;
    }
  }
  .library-page-wrap {
    .audiobooks-wrap {
      .audiobooks-list {
        overflow-x: auto;
        @include smooth-scroll-x();
      }
    }

  }
}
