.astrology-page-wrap {
  .mob-head-title {
    margin: -12px 0 5px;
    padding: 13px 18px;
    font-size: 16px;
    font-weight: bold;
    color: $white;
    background-color: #2F333B;;
  }
  .title-main {
    font-family: 'Alegreya SC', sans-serif;
    font-size: 32px;
    font-weight: 500;
    @media (max-width: $screen-sm) {
      font-size: 28px;
    }
  }
  .forecasts-block-wrap {
    padding: 56px 0 125px;
    position: relative;
    color: $white;
    &.second-tabs {
      padding-bottom: 0;
      .forecast-start {
        margin-bottom: 85px;
      }
    }
    .title-block {
      margin-bottom: 36px;
    }
    .bg-block {
      position: absolute;
      top: 0;
      left: 72px;
      width: calc(100% - 144px);
      aspect-ratio: 1;
      background: url('/static/img/wheel.png') center 12px no-repeat;
      z-index: -1;
      &.active {

      }
    }
    .title-main {
      margin-bottom: 22px;
      font-family: 'Alegreya SC', sans-serif;
      font-size: 32px;
      font-weight: 500;
    }
    .img-block {
      margin-right: 32px;
      max-width: 270px;
      width: 100%;
      flex-grow: 1;
      background-size: 100% 100%;
      aspect-ratio: 1;
    }
    .form-block {
      padding-top: 18px;
      max-width: 540px;
      flex-grow: 1;
      .title {
        margin-bottom: 18px;
        font-size: 22px;
        font-weight: 700;
      }
      .custom-input-block {
        margin-bottom: 30px;
        input {
          background-color: $white;
        }
      }
    }
    .date-list {
      padding: 2px;
      border-radius: 8px;
    }
    .date-block {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      font-size: 14px;
      color: $textDefault;
      font-weight: bold;
      height: 38px;
      border-radius: 7px;
      cursor: pointer;
      &.active {
        color: $white;
        background: $black;
        box-shadow: 0 2px 10px rgba(31, 34, 41, 0.12);
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .card-block {
      width: 209px;
      font-size: 210px;
      color: $white;
      margin-right: 30px;
    }
    .sign {
      font-size: 22px;
    }
    .play-list {
      padding-bottom: 8px;
      margin-left: -36px;
      overflow-x: auto;
    }
    .player-block {
      padding: 26px 14px 14px;
      margin-left: 36px;
      min-width: 345px;
      width: calc(33.33% - 36px);
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.14);
      border-radius: 4px;
      background-color: $white;
      .card-block {
        margin-right: 18px;
        width: 127px;
        flex-shrink: 0;
        box-shadow: none;
        @include aspect-ratio(9, 14, 'inner-wrap');
        .icon {
          position: absolute;
          top: -12px;
          right: -10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 33px;
          height: 33px;
          font-size: 17px;
          color: $textDefault;
          border-radius: 50%;
          background-color: #BD87F8;
        }
      }
      .player-info-block {
        padding: 21px 0 30px;
        width: 100%;
        background-color: $white;
        .icon {
          font-family: 'icomoon' !important;
          speak: never;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;

          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          &.play {
            margin-right: 10px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 47px;
            height: 47px;
            border-radius: 50%;
            color: $white;
            background-color: $textDefault;
            font-size: 50px;
            &:before {
              content: "\e915";
            }
            &.active {
              &:before {
                content: "\e914";
              }
            }
          }
          &.sound {
            flex-shrink: 0;
            margin: -9px 0 0 14px;
            color: $textDefault;
            font-size: 24px;
            width: auto;
            height: auto;
            background-color: transparent;
            &:before {
              content: "\e91d";
            }
            &.active {
              &:before {
                content: "\e913";
              }
            }
          }
        }
        .info-block {
          .title {
            font-size: 16px;
            font-weight: bold;
          }
          .date {
            font-size: 14px;
            color: #798795;
          }
        }
        .progress-wrap {
          width: 100%;
          margin-bottom: 3px;
          .date {
            font-size: 11px;
            color: #798795;
          }
        }
        .progress-block {
          position: relative;
          height: 6px;
          width: 100%;
          background: #E8E8ED;
          box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.09);
          border-radius: 26px;
          .line {
            left: 0;
            height: 100%;
            background: #3FCBFF;
            border-radius: 26px;
          }
        }
      }
    }
    .carousel-inner {
      width: auto;
    }
    .interesting-facts-block {
      padding: 50px 0;
      .title-main {
        margin-bottom: 20px;
      }
      .img-block {
        margin-right: 36px;
        flex-shrink: 0;
        max-width: 445px;
        width: 100%;
        @include aspect-ratio(16, 9, 'inner-wrap');
      }
      .toggle-block {
        font-size: 18px;
        .text {
          height: 148px;
        }
        a {
          .text {
            color: $textDefault;
          }
        }
      }
      @media (max-width: $screen-sm) {
        padding: 30px 0 15px;
        .img-block {
          margin: 0 0 20px;
          max-width: inherit;
        }
        .toggle-block {
          font-size: 16px;
        }
      }
    }
    @media (max-width: $screen-sm) {
      padding: 12px 0 45px;
      .title-main {
        margin-bottom: 25px;
      }
      .forecast-start {
        .img-block {
          position: absolute;
          top: 13px;
          bottom: 48px;
          left: 0;
          width: 100%;
          max-width: inherit;
          opacity: .3;
          z-index: -2;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
      .bg-block {
        height: 550px;
        background-size: auto 100%;
      }
      .form-block {
        padding-top: 0;
        max-width: inherit;
        width: auto;
        .title {
          margin-bottom: 28px;
        }
        .link {
          font-size: 14px;
          color: $textDefault;
        }
      }
      .player-block {
        display: none;
        padding: 12px 12px 8px 8px;
        width: 100%;
        &:first-child {
          display: block;
        }
        .card-block {
          margin-right: 10px;
          width: 92px;
          .icon {
            top: -6px;
            right: -8px;
            width: 17px;
            height: 17px;
            font-size: 8px;
          }
        }
        .player-info-block {
          padding: 10px 0 14px;
          .icon {
            &.play {
              width: 25px;
              height: 25px;
              font-size: 26px;
            }
            &.sound {
              margin: -4px 0 0 14px;
              font-size: 12px;
            }
          }
          .info-block {
            .title {
              margin-bottom: 4px;
              font-size: 14px;
            }
            .date {
              font-size: 13px;
            }
          }
        }
      }
      .carousel-inner {
        margin: 0 -20px;
      }
      .card-block {
        width: 68px;
        &:first-child {
          margin-right: 3px;
        }
      }
      .sign {
        font-size: 14px;
      }
      .second-tabs {
        margin-bottom: 45px;
      }
    }
  }
  .date-tab-list {
    .date-tab {
      display: none;
      &.active {
        display: block;
      }
    }
  }
  .service-consult-wrap {
    .carousel-inner {
      margin: 0 -16px;
      padding: 20px 16px;
    }
    .control-block {
      @include control-block(-60px);
    }
  }
  .pick-sign-wrap {
    .left-block {
      max-width: 365px;
    }
    .right-block {
      max-width: 385px;
    }
    .text {
      margin-bottom: 20px;
      font-size: 16px;
      color: $black;
    }
    .card-list {
      padding-bottom: 0;
      margin-left: -7px;
    }
    .card-block {
      margin: 0 0 7px 7px;
      width: 84px;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.14);
      border-radius: 8px;
      border: 1px solid $white;
      cursor: pointer;
      &.active {
        border-color: $black;
      }
    }
    @media (max-width: $screen-sm) {
      .left-block,
      .right-block {
        margin-left: 0;
        max-width: none;
        width: 100%;
      }
      .left-block {
        padding-top: 12px;
      }
      .card-list {
        margin-bottom: 30px;
      }
      .card-block {
        margin: 0 0 4px 4px;
        width: 52px;
      }
    }
  }
  .sign-result-wrap {
    position: relative;
    .card-block {
      margin: 0 75px 0 0;
      flex-shrink: 0;
      width: 340px;
      border-radius: 8px;
      aspect-ratio: 11.5/12;
      background-size: 100% 100%;
    }
    .info-block {
      flex-grow: 1;
      .sign-block {
        margin-bottom: 8px;
        font-size: 20px;
        color: $textDefault;
      }
      .text {
        margin-bottom: 28px;
        font-size: 18px;
        color: #474A51;
      }
      .other-link {
        color: $textDefault;
        &:hover {
          text-decoration: none;
        }
      }
      .desc {
        font-size: 16px;
        color: #979797;
      }
    }
    @media (max-width: $screen-sm) {
      .card-block {
        margin: 0 0 18px 0;
        width: 115px;
        aspect-ratio: 9/13;
      }
      .info-block {
        .sign-block {
          position: absolute;
          top: 18px;
          left: 154px;
          right: 16px;
          font-size: 18px;
        }
        .text {
          font-size: 14px;
        }
      }
    }
  }
  .astrology-video-block-preview {
    position: relative;
    height: 100%;
    .preview-block {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-color: $textDefault;
      background-size: contain;
      background-repeat: no-repeat;
      &:after {
        content: '';
        position: absolute;
        height: 63%;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.74) 0%, rgba(0, 0, 0, 0.5) 48.28%, rgba(0, 0, 0, 0.0001) 100%);
      }
    }
    .info-block {
      position: relative;
      padding: 60px 0 30px;
      z-index: 1;
      color: $white;
    }
    .top-block {
      .author {
        font-size: 17px;
      }
      .title {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .bot-block {
      font-size: 30px;
      font-weight: bold;
      color: #DADADA;
    }
    .date {
      margin-bottom: 40px;
      color: #BD87F8;
    }
    .email {
      font-size: 16px;
      font-weight: normal;
      color: #8F9399;
      a {
        color: #BD87F8;
        text-decoration: none;
      }
    }
    @media (max-width: $screen-sm) {
      .preview-block {
        background-size: auto 100%;
      }
      .info-block {
        padding: 18px 0 20px;
      }
      .top-block {
        .author {
          font-size: 13px;
        }
        .title {
          font-size: 14px;
        }
      }
      .bot-block {
        font-size: 18px;
      }
      .date {
        margin-bottom: 46px;
        color: #BD87F8;
      }
      .email {
        font-size: 13px;
      }
    }
  }
  .astrology-video-block {
    .status-block {
      position: absolute;
      top: 32px;
      left: 0;
      color: $textDefault;
      padding: 2px 11px;
      font-size: 13px;
      background-color: #BD87F8;
      border-radius: 10px;
    }
    .player-control {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 75px;
      color: $white;
      cursor: pointer;
      transform: translate(-50%, -50%);
      .icon-play {
        display: block;
      }
      .icon-pause {
        display: none;
      }
      &.paused {
        .icon-play {
          display: none;
        }
        .icon-pause {
          display: block;
        }
      }
    }
    .info-block {
      position: absolute;
      left: 28px;
      bottom: 18px;
      z-index: 1;
      .author {
        color: #DADADA;
        font-size: 14px;
      }
      .name {
        color: $white;
        font-size: 16px;
      }
      .email {
        color: #8F9399;
        font-size: 13px;
        a {
          text-decoration: none;
          color: #BD87F8;
        }
      }
    }
    .player-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $textDefault;
      &:after {
        content: '';
        position: absolute;
        height: 63%;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.74) 0%, rgba(0, 0, 0, 0.5) 48.28%, rgba(0, 0, 0, 0.0001) 100%);
      }
      .player-body {
        position: relative;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        max-width: 570px;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: center;
        .player {
          height: 100%;
          width: 100%;
        }
      }
    }
    .chat-wrap {
      position: absolute;
      right: 72px;
      bottom: 32px;
      color: $textDefault;
      .chat-open-state-block {
        position: relative;
        z-index: 1;
      }
      .chat-toggle-block {
        width: 192px;
        background: $white;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.14);
        border-radius: 12px;
        .text {
          font-size: 15px;
        }
      }
      .top-block {
        background: $white;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.14);
        border-radius: 12px;
      }
      .chat-title-block {
        padding: 12px 16px;
        .icon {
          flex-shrink: 0;
          cursor: pointer;
        }
        .icon-chat {
          margin-right: 10px;
          font-size: 24px;
        }
        .icon-close {
          font-size: 16px;
        }
        .icon-plus {
          font-size: 24px;
        }
      }
      .message-list-wrap {
        margin-bottom: 5px;
        padding: 0 6px 6px 0;
      }
      .messages-list {
        padding: 10px 6px 6px 10px;
        color: $black;
        overflow-y: auto;
        max-height: 105px;
        @include scrollbar(6px, auto, #E8E8ED, #3FCBFF);
        .message-block {
          margin-bottom: 10px;
        }
      }
      .message-block {
        .avatar {
          margin-right: 8px;
          flex-shrink: 0;
          width: 17px;
          height: 17px;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        }
        .message {
          font-size: 13px;
        }
      }
      .input-block {
        position: relative;
        border-radius: 4px;
        input {
          padding: 12px 65px 12px 12px;
          color: $black;
          background-color: #F5F5FA;
          @include placeholder(#8F9399);
        }
        .icon-enter1 {
          position: absolute;
          top: 12px;
          right: 10px;
          font-size: 24px;
          cursor: pointer;
        }
      }
      @media (max-width: $screen-sm) {
        left: 5px;
        right: 5px;
        bottom: 0;
        .input-block {
          margin: 0 -5px;
          input {
            border-radius: 0;
          }
        }
        .chat-toggle-block {
          width: 92px;
          .icon-close {
            display: none;
          }
        }
      }
    }
    .video-info-block {
      position: absolute;
      top: 60px;
      left: 12px;
      .author {
        font-size: 17px;
        color: #E3E3E3;
      }
      .title {
        font-size: 20px;
        color: $white;
        font-weight: bold;
      }
    }
    .bot-block {
      position: absolute;
      height: 36px;
      left: 0;
      right: 0;
      bottom: 0;
      .progress-active-zone {
        position: absolute;
        top: -14px;
        display: flex;
        align-items: center;
        height: 30px;
        width: 100%;
        cursor: pointer;
      }
      .progress-bar {
        width: 100%;
        height: 2px;
        background-color: $white;
        .progress-played {
          top: 0;
          left: 0;
          width: 20px;
          height: 100%;
          background-color: #BD87F8;
        }
        .progress-thumb {
          position: absolute;
          top: 0;
          left: 0;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:before {
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #BD87F8;
          }
        }
      }
      .timer-wrap {
        height: calc(100% - 2px);
      }
      .left-block {
        color: #8F9399;
        a {
          color: #BD87F8;
        }
      }
      .timer-block {
        color: $white;
        font-size: 13px;
      }
    }
    @media (max-width: $screen-sm) {
      .status-block {
        top: 16px;
        left: 10px;
      }
      .info-block {
        position: absolute;
        top: 16px;
        right: 10px;
        left: inherit;
        bottom: inherit;
        .author {
          color: $white;
        }
      }
    }
  }
  .predictions-wrap-express {
    padding-bottom: 36px;
    background-color: $white;
  }
  .predictions-new-wrap {
    padding-top: 25px;
    color: $textDefault;
  }

  .forecast-result {
    padding-bottom: 145px;
  }
  .psychology-page-wrap {
    .control-block {
      flex-grow: 1;
      @include control-block(-60px);
    }
  }
  .psychology-page-wrap .expert-articles-wrap {
    padding: 0;
    background-color: transparent;
    .carousel-inner {
      margin: 0;
      padding: 0;
    }
    .article-block {
      .img-block {
        top: inherit;
        bottom: inherit;
      }
    }
  }
  .human-design-page-wrap {
    .service-consult-wrap {
      padding-top: 38px;
      .specialist-block {
        @media (max-width: $screen-sm) {
          max-width: 365px;
        }
      }
    }
    &.astro-events {
      background-color: #1c1c29;
      .title-main {
        margin-bottom: 32px;
        color: $white;
      }
      .tab-list {
        .tab {
          padding: 0 0 18px;
          color: $white;
          &.active:after {
            background-color: $white;
          }
        }
      }
      .specialist-block {
        .date {
          font-size: 14px;
          color: $white;
        }
        .title {
          color: $white;
        }
      }
      .tag-block-default {
        color: $white;
      }
      .service-consult-wrap {
        padding: 36px 0;
      }
      .experts-block-wrap {
        padding: 0 0 20px;
        .title-main {
          margin-bottom: 20px;
        }
        .tag-block-default {
          color: $black;
        }
      }
    }
  }
  .specialist-block {
    .img-block {
      margin-right: 0;
      max-width: inherit;
      border-radius: 10px;
    }
  }
}

.personal-astrologic-profile-wrap {
  padding: 28px 0 52px;
  color: $white;
  background-color: $black;
  .title-main {
    margin-bottom: 18px;
    font-size: 32px;
    font-weight: 500;
    font-family: 'Alegreya SC', sans-serif;
  }
  .text {
    margin-bottom: 28px;
    font-size: 18px;
  }
  .tab-list {
    margin-bottom: 28px;
    border-color: rgba(255, 255, 255, 0.3);
    .tab {
      margin-right: 18px;
      padding: 0 0 16px;
      color: $white;
      flex-grow: 0;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        &:after {
          background-color: $white;
        }
      }
    }
  }
  .form-group {
    margin-bottom: 28px;
    label {
      margin-bottom: 10px;
      color: $white;
    }
    input,
    .custom-select-block {
      height: 60px;
      @include placeholder($white);
    }
    .d-flex {
      input {
        border-radius: 0 8px 8px 0;
      }
    }
    .custom-select-block {
      width: 126px;
      flex-shrink: 0;
      .select-input-block {
        padding: 18px 12px 9px;
        font-size: 13px;
        color: $white;
        border-color: $white;
        background-color: transparent;
        border-radius: 8px 0 0 8px;
        &:after {
          right: 5px;
          color: $white;
        }
      }
      .select-list {
        label {
          color: $textDefault;
          font-size: 13px;
        }
      }
    }
  }
  .row-block {
    display: flex;
    gap: 0 36px;
    .form-group {
      width: calc(33.3% - 36px);
    }
  }
  .btn {
    padding: 11px 35px;
    border-radius: 0;
  }
  @media (max-width: $screen-sm) {
    padding: 28px 0 32px;
    .form-group {
      margin-bottom: 8px;
    }
    .row-block {
      margin-bottom: 18px;
      flex-flow: column nowrap;
      gap: 18px 0;
      .form-group {
        margin-bottom: 0;
        width: 100%;
      }
    }
  }
}
