$screen-sm: 768px;
$textDefault: #1F2229;
$white: #fff;
$black: #000;
$gray: #626469;
$gray2: #7C8792;
$red: #D81B3B;
$green: #41A674;
$gray400: #798795;
$bronze: #BF9754;
$purple: #bd87f8;
