.main-page-wrap {
  .title-main {
    margin-bottom: 18px;
    font-size: 32px;
    font-weight: 500;
    font-family: 'Alegreya SC', sans-serif;
    @media (max-width: $screen-sm) {
      font-size: 28px;
    }
  }
  .promo-block {
    position: relative;
    color: $textDefault;
    padding: 113px 0 115px;
    .bg-block {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      &:before,
      &:after {
        content: '';
        display: block;
        flex-grow: 1;
        height: 100%;
      }
      &:before {
        background-color: #c882ff;
      }
      &:after {
        background-color: #fff;
      }
      .inner-block {
        max-width: 1260px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .info-block {
      max-width: 495px;
      position: relative;
      color: $textDefault;
    }
    .title {
      margin-bottom: 20px;
      font-size: 40px;
      font-family: 'Alegreya SC', sans-serif;
      font-weight: bold;
      text-transform: uppercase;
    }
    .text {
      font-size: 22px;
    }
    @media (max-width: $screen-sm) {
      color: $white;
      padding: 0;
      background-color: transparent;
      .title {
        margin-bottom: 10px;
        font-size: 18px;
      }
      .text {
        font-size: 15px;
      }
      .bg-block {
        position: relative;
        width: 100%;
        height: 225px;
        &:before,
        &:after {
          display: none;
        }
        &:before {
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: calc(225px - 83px) 0 0 calc(100vw - 36px);
          border-color: transparent transparent transparent #C882FF;
          background-color: transparent;
        }
        .inner-block {
          background-size: auto 100%;
          mix-blend-mode: multiply;
        }
      }
      .info-block {
        color: $white;
      }
      .container-fluid {
        padding: 20px 16px;
        background-color: $textDefault;
      }
    }

  }
  .chapters-wrap {
    padding: 30px 0 60px;
    .chapters-list {
      margin-left: -36px;
    }
    .chapter-block {
      margin: 0 0 36px 36px;
      padding: 18px;
      flex-shrink: 0;
      width: calc(33.3% - 36px);
      min-height: 210px;
      background: $white;
      box-shadow: 0 4px 16px rgba(31, 34, 41, 0.12);
      .title {
        margin-bottom: 18px;
        font-family: 'Alegreya SC', sans-serif;
        font-size: 24px;
        font-weight: bold;
      }
      a {
        margin-bottom: 15px;
        color: $textDefault;
        font-size: 14px;
        &:hover {
          text-decoration: none;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      .inner-block {
        height: 100%;
        &.astrology {
          background: url('/static/img/svg/chapter/astrology.svg') right bottom no-repeat;
        }
        &.taro {
          background: url('/static/img/svg/chapter/taro.svg') right bottom no-repeat;
        }
        &.numerology {
          background: url('/static/img/svg/chapter/numerology.svg') right bottom no-repeat;
        }
        &.psychology {
          background: url('/static/img/svg/chapter/psychology.svg') right bottom no-repeat;
        }
        &.human-design {
          background: url('/static/img/svg/chapter/human-design.svg') right bottom no-repeat;
        }
        &.library {
          background: url('/static/img/svg/chapter/library.svg') right bottom no-repeat;
        }
      }
    }
    @media (max-width: $screen-sm) {
      padding: 5px 0 15px;
      .chapters-list {
        padding: 15px 0;
        overflow-y: auto;
        margin-left: -10px;
      }
      .chapter-block {
        margin: 0 0 0 10px;
        padding: 18px 18px 30px;
        width: calc(100% - 35px);
        height: auto;
        .inner-block {
          height: 100%;
          &.astrology {
            background-size: 48px 48px;
          }
          &.taro {
            background-size: 48px 48px;
          }
          &.numerology {
            background-size: 48px 48px;
          }
          &.psychology {
            background-size: 48px 48px;
          }
          &.human-design {
            background-size: 48px 48px;
          }
          &.library {
            background-size: 48px 48px;
          }
        }
        .link-list {
          margin-right: 50px;
        }
      }
    }
  }
  .predictions-wrap {
    position: relative;
    padding: 60px 0 160px;
    .bg-block {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('/static/img/svg/predictions-bg.svg') center top / 100% auto no-repeat;
    }
    .title-main {
      margin-bottom: 18px;
    }
    .title {
      margin-bottom: 18px;
      font-size: 22px;
      font-family: 'Alegreya SC', sans-serif;
      font-weight: bold;
    }
    .container-fluid {
      position: relative;
    }
    .prediction-block {
      display: flex;
      flex-flow: column nowrap;
      width: calc(50% - 18px);
      flex-shrink: 0;
      &:first-child {
        margin-right: 36px;
      }
    }
    .card-block {
      position: relative;
      flex-grow: 1;
      padding: 24px 18px;
      background: $white;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.14);
      border-radius: 4px;
      .img-block {
        margin-right: 20px;
        flex-shrink: 0;
        width: 168px;
        align-self: start;
        @include aspect-ratio(9, 14, 'inner-wrap');
        background-size: 100% 100%;
      }
      .info-block {
        margin-bottom: 15px;
        padding-top: 10px;
      }
      .title {
        margin-bottom: 18px;
        font-size: 16px;
        font-weight: bold;
      }
      .toggle-block {
        .text {
          height: 140px;
          overflow: hidden;
        }
        &.show {
          .text {
            height: auto;
          }
        }
      }
      .text {
        font-size: 15px;
        color: #474A51;
        .gray {
          font-size: 14px;
          color: #979797;
        }
      }
      .btn-toggle {
        .text {
          font-size: 16px;
        }
      }
    }
    @media (max-width: $screen-sm) {
      padding: 30px 0 15px;
      .title {
        font-size: 20px;
      }
      .predictions-list {
        overflow-x: auto;
        padding-bottom: 15px;
      }
      .prediction-block {
        width: calc(100% - 56px);
        &:first-child {
          margin-right: 8px;
        }
      }
      .card-block {
        position: relative;
        padding: 14px 15px;
        .img-block {
          width: 54px;
        }
        .title {
          position: absolute;
          top: 20px;
          left: 97px;
          right: 25px;
        }
        .info-block {
          padding-top: 5px;
        }
      }
    }
  }
  .predictions-wrap-express {
    padding: 60px 0 0;
  }
  .promo-block-bot {
    margin-bottom: 62px;
    position: relative;
    color: $white;
    .inner-block {
      position: relative;
      padding: 24px 0 20px;
      background: linear-gradient(91.08deg, #551993 0%, #C185FF 100%);
      border-radius: 4px;
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
      }
      &:before {
        left: -30px;
        width: 398px;
        height: 200px;
        background: url('/static/img/dragon1.png') no-repeat;
      }
      &:after {
        right: -44px;
        width: 228px;
        height: 185px;
        background: url('/static/img/dragon2.png') no-repeat;
      }
    }
    .info-block {
      width: 650px;
      margin: 0 auto;
    }
    .title {
      margin-bottom: 10px;
      font-size: 30px;
      font-weight: bold;
    }
    .text {
      margin-bottom: 15px;
      font-size: 14px;
    }
    @media (max-width: $screen-sm) {
      margin-bottom: 30px;
      .inner-block {
        padding: 15px 12px 75px;
        &:before {
          left: -10px;
          width: 150px;
          height: 76px;
          background-size: cover;
        }
        &:after {
          right: -10px;
          width: 136px;
          height: 110px;
          background-size: cover;
        }
      }
      .info-block {
        width: 100%;
      }
      .title {
        font-size: 20px;
      }
      .text {
        font-size: 13px;
      }
    }
  }
}
