.library-page-wrap {
  .title-main {
    font-family: 'Alegreya SC', sans-serif;
    font-weight: bold;
    font-size: 32px;
  }

  .all-link {
    color: $textDefault;
  }

  .magazines-wrap {
    padding: 40px 0 70px;
    .title-block {
      position: relative;
      margin-bottom: 20px;
    }
    .date {
      margin-bottom: 20px;
      font-size: 22px;
      font-family: 'Alegreya SC', sans-serif;
      font-weight: bold;
    }
    .magazine-list {
      margin-left: -36px;
      overflow-x: auto;
      @include smooth-scroll-x();
    }
    .magazine-block {
      flex-shrink: 0;
      margin-left: 36px;
      width: calc(20% - 36px);
    }
    .img-block {
      margin-bottom: 20px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      @include aspect-ratio(9, 12.5, 'inner-wrap');
    }
    .title {
      margin-bottom: 25px;
      font-size: 14px;
      color: $black;
    }
    .btn {
      margin-right: 18px;
      font-weight: bold;
      padding: 7px 19px;
    }
    a {
      color: $textDefault;
      &.btn-dark {
        color: $white;
      }
    }
  }

  .audiobooks-wrap {
    padding: 60px 0 58px;
    .title-main {
      margin-bottom: 28px;
    }
    .audiobooks-list {
      margin-left: -36px;
    }
    .audiobook-block {
      color: $black;
      margin: 0 0 36px 36px;
      flex-shrink: 0;
      width: calc(16.6% - 36px);
      .img-block {
        margin-bottom: 12px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include aspect-ratio(1,1, 'inner-wrap');
      }
      .title {
        margin-bottom: 6px;
        font-size: 15px;
      }
      .desc {
        font-size: 12px;
        margin-bottom: 8px;
      }
      .more {
        margin-bottom: 10px;
        display: inline-block;
        vertical-align: top;
        color: $textDefault;
        font-size: 16px;
      }
      .icon-play {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 32px;
        width: 30px;
        height: 30px;
        color: $white;
        border-radius: 50%;
        background-color: $textDefault;
        cursor: pointer;
      }
      .price {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: bold;
      }
      .btn {
        padding: 8px 20px;
      }
    }
  }

  .books-wrap {
    padding: 70px 0 40px;
    .title-main {
      margin-bottom: 30px;
    }
    .books-list {
      margin-left: -36px;
    }
    .book-block {
      flex-shrink: 0;
      margin: 0 0 36px 36px;
      width: calc(20% - 36px);
      .right-block {
        margin-left: 0;
        flex-grow: 1;
      }
    }
    .img-block {
      margin-bottom: 15px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      @include aspect-ratio(9, 14, 'inner-wrap');
    }
    .authors {
      margin-bottom: 5px;
      font-size: 13px;
      color: #798795;
    }
    .title {
      margin-bottom: 40px;
      font-size: 14px;
      color: $black;
    }
    .btn {
      margin-right: 18px;
      display: inline-block;
      font-weight: bold;
      padding: 7px 19px;
    }
  }

  .banner-block {
    margin-bottom: 60px;
    position: relative;
    min-height: 187px;
    display: flex;
    align-items: center;
    background: url('/static/img/banner-bg.png') center / auto 100% no-repeat;
    .text-block {
      padding: 25px 0 20px;
      margin: 0 auto;
      max-width: 600px;
      font-size: 16px;
      text-align: center;
    }
    .img1 {
      display: none;
      position: absolute;
      right: -5px;
      bottom: -32px;
      width: 117px;
      height: 125px;
      background: url('/static/img/banner-img1.png') center no-repeat;
    }
    p {
      margin: 0 0 12px;
      font-weight: bold;
      color: $white;
      span {
        color: #c590ff;
      }
    }
    a {
      color: $white;
    }
  }

  .reading-block-wrap {
    position: relative;
    height: calc(100vh - 76px);
    padding: 20px 45px 0;
    background-color: #BFC6D1;
    overflow: hidden;
    .reading-nav-block {
      position: absolute;
      margin-left: -50px;
      left: 50%;
      bottom: -55px;
      padding-top: 15px;
      width: 100px;
      height: 100px;
      font-size: 15px;
      font-weight: bold;
      color: $white;
      text-align: center;
      border-radius: 50%;
      background-color: #BD87F8;
    }
    .icon {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 57px;
      height: 57px;
      font-size: 34px;
      color: $white;
      border-radius: 50%;
      background-color: #10375A;
    }
    .journal-block {
      margin: 0 30px;
      flex-grow: 1;
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  @media (max-width: $screen-sm) {
    .title-main {
      font-size: 28px;
    }
    .magazines-wrap {
      padding: 20px 0 0;
      .title-block {
        margin-bottom: 15px;
      }
      .date {
        margin-bottom: 15px;
        font-size: 20px;
      }
      .magazine-list {
        padding-bottom: 30px;
        margin-left: -10px;
      }
      .magazine-block {
        flex-shrink: 0;
        margin-left: 10px;
        width: calc(192px - 10px);
      }
    }
    .audiobooks-wrap {
      padding: 30px 0 38px;
      .title-main {
        font-size: 28px;
      }
      .audiobooks-list {
        margin-bottom: 20px;
        margin-left: -14px;
        .audiobook-block {
          display: none;
          &:nth-child(-n+4) {
            display: block;
          }
        }
      }
      .audiobook-block {
        color: $black;
        margin: 0 0 20px 14px;
        width: calc(50% - 14px);
        .img-block {
          margin-bottom: 12px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          @include aspect-ratio(1,1, 'inner-wrap');
        }
        .title {
          margin-bottom: 8px;
          font-size: 13px;
        }
      }
    }
    .books-wrap {
      padding: 30px 0 50px;
      .title-main {
        margin-bottom: 25px;
        font-size: 28px;
      }
      .books-list {
        margin: 0 0 20px 0;
      }
      .book-block {
        flex-shrink: 0;
        margin: 0 0 20px;
        width: 100%;
        .right-block {
          flex-shrink: 1;
        }
        .left-block {
          margin-right: 10px;
          flex-shrink: 0;
          width: 108px;
        }
      }
      .img-block {
        margin-bottom: 0;
        @include aspect-ratio(9, 14, 'inner-wrap');
      }
      .top-block {
        padding-top: 14px;
      }
      .title {
        margin-bottom: 15px;
      }
      .btn {
        margin-right: 18px;
        display: inline-block;
        font-weight: bold;
        padding: 7px 19px;
      }
    }
    .banner-block {
      position: relative;
      padding: 20px 32px 20px 15px;
      display: block;
      background: linear-gradient(91.08deg, #3A0057 0%, #7A08B4 100%);
      border-radius: 4px;
      .text-block {
        text-align: left;
        padding: 0;
        font-size: 14px;
      }
      .img1 {
        display: block;
        position: absolute;
        right: -5px;
        bottom: -32px;
        width: 117px;
        height: 125px;
        background: url('/static/img/banner-img1.png') center no-repeat;
      }
      p {
        margin: 0 0 15px;
        font-weight: bold;
        color: $white;
        span {
          color: #c590ff;
        }
      }
      a {
        font-size: 16px;
        color: $white;
      }
    }
    .reading-block-wrap {
      padding: 10px 18px 120px;
      height: calc(100vh - 53px);
      .journal-block {
        margin: 0 -12px;
      }
      .reading-nav-block {
        margin-left: -25px;
        font-size: 14px;
        width: 52px;
        height: 52px;
        bottom: 44px;
      }
      .icon {
        position: relative;
        font-size: 14px;
        width: 24px;
        height: 24px;
        z-index: 1;
      }
    }
  }
}


