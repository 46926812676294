.main-page-wrap.new {
  color: $white;
  background: url('/static/img/svg/wheel.svg') center 20px no-repeat;
  .title-block {
    padding: 160px 0 130px;
    margin: 0 auto;
    text-align: center;
    .title {
      margin-bottom: 24px;
      font-family: 'Alegreya SC', sans-serif;
      font-size: 40px;
      font-weight: 700;
      text-transform: uppercase;
    }
    .text-block {
      font-size: 22px;
      font-weight: 700;
    }
    @media (max-width: $screen-sm) {
      padding: 2px 0 14px;
      .title {
        margin-bottom: 12px;
        color: $bronze;
        font-size: 28px;
      }
      .text-block {
        font-size: 20px;
      }
    }
  }
  .categories-list {
    margin-right: -16px;
    padding-bottom: 40px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 32px;
    .category-block {
      display: flex;
      color: $black;
      padding: 18px;
      border-radius: 20px;
      min-height: 212px;
      background-color: $white;
      .img-block {
        margin-right: 12px;
        height: 100%;
        max-width: 160px;
        width: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      .info-block {
        display: flex;
        flex-flow: column nowrap;
      }
      .title {
        margin-bottom: 12px;
        font-size: 20px;
        font-weight: 700;
      }
      a {
        margin-bottom: 12px;
        font-size: 14px;
        color: $black;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .category-block {
      &:nth-child(1) {
        .img-block {
          background-image: url('/static/img/categories/astrology.png');
        }
      }
      &:nth-child(2) {
        .img-block {
          background-image: url('/static/img/categories/taro.png');
        }
      }
      &:nth-child(3) {
        .img-block {
          background-image: url('/static/img/categories/numeric.png');
        }
      }
      &:nth-child(4) {
        .img-block {
          background-image: url('/static/img/categories/psychology.png');
        }
      }
      &:nth-child(5) {
        .img-block {
          background-image: url('/static/img/categories/human-design.png');
        }
      }
      &:nth-child(6) {
        .img-block {
          background-image: url('/static/img/categories/library.png');
        }
      }
      &:nth-child(7) {
        .img-block {
          background-image: url('/static/img/categories/hand.png');
        }
      }
      &:nth-child(8) {
        .img-block {
          background-image: url('/static/img/categories/specialists.png');
        }
      }
      &:nth-child(9) {
        .img-block {
          background-image: url('/static/img/categories/game.png');
        }
      }
    }
    @media (max-width: $screen-sm) {
      display: flex;
      gap: 0 8px;
      overflow-x: auto;
      .category-block {
        padding: 10px 12px;
        flex-shrink: 0;
        width: 260px;
        height: auto;
        .img-block {
          width: 96px;
        }
      }
    }
  }
  .predictions-wrap {
    position: relative;
    padding: 32px 0 65px;
    color: $white;
    background-color: $black;
    .title-main {
      margin-bottom: 18px;
      font-family: 'Alegreya SC', sans-serif;
      font-size: 32px;
      font-weight: 500;
    }
    .title {
      margin-bottom: 18px;
      font-size: 22px;
      font-weight: normal;
    }
    .container-fluid {
      position: relative;
    }
    .prediction-block {
      display: flex;
      flex-flow: column nowrap;
      width: calc(50% - 18px);
      flex-shrink: 0;
      &:first-child {
        margin-right: 36px;
      }
    }
    .card-block {
      position: relative;
      flex-grow: 1;
      padding: 18px;
      color: $white;
      background-color: transparent;
      .img-block {
        margin-right: 20px;
        flex-shrink: 0;
        width: 168px;
        align-self: start;
        @include aspect-ratio(9, 14, 'inner-wrap');
        background-size: 100% 100%;
      }
      .info-block {
        margin-bottom: 15px;
        padding-top: 10px;
      }
      .title {
        margin-bottom: 18px;
        font-size: 16px;
        font-weight: bold;
      }
      .toggle-block {
        .text {
          height: 136px;
          overflow: hidden;
        }
        &.show {
          .text {
            height: auto;
          }
        }
      }
      .text {
        font-size: 14px;
        line-height: 22px;
        color: $white;
      }
      .btn-toggle {
        .text {
          font-size: 14px;
          color: $white;
        }
      }
    }
    @media (max-width: $screen-sm) {
      padding: 16px 0 32px;
      .title {
        font-size: 20px;
      }
      .predictions-list {
        overflow-x: auto;
        padding-bottom: 15px;
      }
      .prediction-block {
        width: calc(100% - 56px);
        &:first-child {
          margin-right: 8px;
        }
      }
      .card-block {
        position: relative;
        padding: 14px 15px;
        .img-block {
          width: 54px;
        }
        .title {
          position: absolute;
          top: 20px;
          left: 97px;
          right: 25px;
        }
        .info-block {
          padding-top: 5px;
        }
      }
    }
  }
  .predictions-wrap-express {
    padding: 36px 0 72px;
    color: $black;
    background-color: $white;
    .title-main {
      font-family: 'Alegreya SC', sans-serif;
      font-size: 32px;
      font-weight: 500;
    }
    .individual-predictions-wrap,
    .predictions-page-wrap,
    .predictions-wrap-express {
      padding-bottom: 0;
    }
  }
  .banner-block-new {
    margin-bottom: 36px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    color: $white;
    height: 190px;
    background: url('/static/img/crystal_bg.png') center / 100% auto no-repeat;
    .title {
      font-family: 'Alegreya SC', sans-serif;
      font-size: 30px;
      font-weight: 800;
      margin-bottom: 8px;
    }
    .text {
      font-weight: 700;
    }
    @media (max-width: $screen-sm) {
      background: url('/static/img/crystal_bg_mobile.png') center / cover no-repeat;
      .info-block {
        .title {
          margin-bottom: 12px;
        }
        .text {

        }
      }
    }
  }
  .service-consult-wrap {

  }
}
