footer {
  padding: 55px 0;
  font-size: 14px;
  background-color: #1C1C25;
  .copyright {
    margin-bottom: 16px;
    color: $white;
  }
  .text {
    max-width: 485px;
    color: #8F9399;
    a {
      color: #8F9399;
    }
  }
  .footer-menu {
    a {
      margin-left: 32px;
      color: $white;
      text-decoration: none;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

@media (max-width: $screen-sm) {
  footer {
    padding: 30px 0 20px;
    text-align: center;
    .copyright {
      margin-bottom: 24px;
    }
    .text {
      margin: 0 auto 20px;
      max-width: 253px;
      font-size: 13px;
    }
    .footer-menu {
      justify-content: center;
      a {
        margin: 0 0 20px 0;
      }
    }
  }
}
