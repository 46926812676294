/*
  Драконы
*/
$dragon1Width: 437px;
$dragon2Width: 375px;

.dragon-promo {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  &.one {
    width: 437px;
    aspect-ratio: 16/12.3;
    background: url('/static/img/svg/dragons-promo/dragon1.svg') center / 100% 100% no-repeat;
  }
  &.two {
    width: 375px;
    aspect-ratio: 15/15.5;
    background: url('/static/img/svg/dragons-promo/dragon2.svg') center / 100% 100% no-repeat;
  }
  &.three {
    width: 601px;
    aspect-ratio: 16/8.1;
    background: url('/static/img/svg/dragons-promo/dragon3.svg') center / 100% 100% no-repeat;
  }
  &.four {
    width: 227px;
    aspect-ratio: 11.2/16;
    background: url('/static/img/svg/dragons-promo/dragon4.svg') center / 100% 100% no-repeat;
  }
  &.five {
    width: 300px;
    aspect-ratio: 16/11.85;
    background: url('/static/img/svg/dragons-promo/dragon5.svg') center / 100% 100% no-repeat;
  }
  &.six {
    width: 220px;
    aspect-ratio: 11.2/16;
    background: url('/static/img/svg/dragons-promo/dragon6.svg') center / 100% 100% no-repeat;
  }
  &.seven {
    width: 220px;
    aspect-ratio: 11.2/16;
    background: url('/static/img/svg/dragons-promo/dragon7.svg') center / 100% 100% no-repeat;
  }
  &.baby {
    width: 249px;
    aspect-ratio: 11.5/16;
    background: url('/static/img/svg/dragons-promo/dragon-baby.svg') center / 100% 100% no-repeat;
  }
  &.egg {
    width: 172px;
    aspect-ratio: 12.2/16;
    background: url('/static/img/svg/dragons-promo/dragon-egg.svg') center / 100% 100% no-repeat;
  }
}
.crystal-promo {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  &.one {
    width: 85px;
    aspect-ratio: 16/24.5;
    background: url('/static/img/promos/crystals/5.svg') center / 100% 100% no-repeat;
  }
  &.two {
    width: 200px;
    aspect-ratio: 15/15.5;
    background: url('/static/img/promos/crystals/10.svg') center / 100% 100% no-repeat;
  }
  &.three {
    width: 250px;
    aspect-ratio: 16/8.1;
    background: url('/static/img/promos/crystals/25.svg') center / 100% 100% no-repeat;
  }
  &.four {
    width: 290px;
    aspect-ratio: 11.2/16;
    background: url('/static/img/promos/crystals/50.svg') center / 100% 100% no-repeat;
  }
}


/*
 main
*/

.chapters-wrap {
  position: relative;
  .crystal-promo,
  .dragon-promo {
    right: 30px;
    bottom: 0;
    width: 130px;
    opacity: .7;
    @media (max-width: $screen-sm) {
      width: 60px;
      opacity: 1;
    }
  }
  .crystal-promo {
    width: 85px;
    @media (max-width: $screen-sm) {
      width: 45px;
    }
  }
}
.service-consult-wrap {
  .crystal-promo,
  .dragon-promo {
    left: 30px;
    bottom: 350px;
    width: 120px;
    opacity: .5;
    @media (max-width: $screen-sm) {
      top: 20px;
      width: 50px;
      bottom: inherit;
      opacity: 1;
    }
  }
}

/*
 astrology
*/

.forecasts-block-wrap {
  .dragon-promo {
    top: 0;
    left: 0;
    width: 160px;
    opacity: .5;
    @media (max-width: $screen-sm) {
      top: 60px;
      left: inherit;
      right: 10px;
      width: 50px;
      opacity: 1;
    }
  }
}

.human-design-page-wrap {
  .dragon-promo {
    top: 30px;
    left: 50px;
    width: 80px;
    opacity: .6;
    @media (max-width: $screen-sm) {
      left: inherit;
      right: 10px;
    }
  }
}

.card-of-the-day-wrap {
  .dragon-promo {
    top: 30px;
    left: 50px;
    width: 80px;
    opacity: .6;
    @media (max-width: $screen-sm) {
      left: inherit;
      right: 10px;
    }
  }
}

.interesting-facts-block  {
  .dragon-promo {
    top: 30px;
    right: 50px;
    width: 80px;
    opacity: .6;
    @media (max-width: $screen-sm) {
      left: inherit;
      top: -12px;
      right: 10px;
    }
  }
}

.human-design-page-wrap {
  .service-consult-wrap {
    .dragon-promo {
      top: 30px;
      right: 50px;
      width: 80px;
      opacity: .6;
      @media (max-width: $screen-sm) {
        top: 14px;
        right: 10px;
      }
    }
  }
}

.books-wrap {
  .dragon-promo {
    top: 30px;
    left: 50px;
    width: 80px;
    opacity: .6;
    @media (max-width: $screen-sm) {
      left: inherit;
      top: 14px;
      right: 10px;
    }
  }
}

.magazines-wrap {
  .dragon-promo {
    top: 30px;
    left: 250px;
    width: 80px;
    opacity: .6;
    @media (max-width: $screen-sm) {
      left: inherit;
      top: 14px;
      right: 100px;
      width: 40px;
    }
  }
}

.numerology-page-wrap {
  .individual-prediction-wrap {
    .dragon-promo {
      left: 30px;
      width: 80px;
      @media (max-width: $screen-sm) {
        top: 10px;
        left: inherit;
        right: 10px;
      }
    }
  }
  .individual-predictions-wrap {
    .dragon-promo {
      right: 30px;
      width: 100px;
      @media (max-width: $screen-sm) {
        right: 10px;
      }
    }
  }
}

.profile-page-wrap {
  .user-profile-block {
    .dragon-promo {
      top: 30px;
      left: 30px;
      width: 80px;
      @media (max-width: $screen-sm) {
        margin-left: -40px;
        left: 50%;
      }
    }
  }
  .my-notes-wrap {
    .dragon-promo {
      top: 30px;
      left: 30px;
      width: 80px;
      @media (max-width: $screen-sm) {
        left: inherit;
        top: -45px;
        right: 20px;
      }
    }
  }
}

.psychology-page-wrap {
  .psychology-block-wrap {
    .dragon-promo {
      left: 30px;
      width: 50px;
      opacity: .7;
      @media (max-width: $screen-sm) {
        left: inherit;
        right: 20px;
      }
    }
  }
  .audio-meditations-wrap {
    .dragon-promo {
      left: 30px;
      width: 50px;
      opacity: .7;
      @media (max-width: $screen-sm) {
        top: 20px;
        left: inherit;
        right: 20px;
      }
    }
  }
}
