.psychology-page-wrap {
  .control {
    font-weight: bold;
    &.left {
      .icon {
        margin-right: 12px;
      }
    }
    &.right {
      .icon {
        margin-left: 12px;
      }
    }
    .icon {
      font-size: 20px;
    }
  }
  .title-main {
    font-family: 'Alegreya SC', sans-serif;
    font-size: 32px;
    font-weight: 500;
    @media (max-width: $screen-sm) {
      font-size: 28px;
    }
  }
  .psychology-block-wrap {
    padding: 40px 0 60px;
    .title-main {
      margin-bottom: 28px;
      color: $white;
    }
    .img-block {
      margin-right: 36px;
      flex-shrink: 0;
      max-width: 350px;
      width: 100%;
      background-repeat: no-repeat;
      @include aspect-ratio(16, 7, 'inner-wrap');
    }
    .toggle-block {
      font-size: 18px;
      color: $white;
      .text {
        height: 100px;
      }
      .btn-toggle {
        font-size: 16px;
        color: $white;
        .text {
          color: $white;
        }
      }
    }
    @media (max-width: $screen-sm) {
      padding: 20px 0 30px;
      .title-main {
        margin-bottom: 10px;
      }
      .img-block {
        margin-right: 0;
      }
      .text {
        font-size: 14px;
      }
    }
  }
  .expert-articles-wrap {
    padding: 50px 0 60px;
    background-color: #F5F5FA;
    .control-block {
      @include control-block();
    }
    .tab-list {
      margin-bottom: 30px;
    }
    .tab {
      margin-right: 36px;
      flex-grow: 0;
      padding: 8px 2px 18px;
    }
    .article-block {
      .img-block {
        margin-right: 36px;
        width: 100%;
        max-width: 350px;
        flex-shrink: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include aspect-ratio(16, 9, 'inner-wrap');
      }
      .info-block {
        padding-top: 8px;
        .title {
          font-size: 16px;
          font-weight: bold;
        }
        .text {
          font-size: 14px;
        }
        .text-block {
          .text {
            height: 60px;
            overflow: hidden;
          }
        }
        .auth-name {
          margin: 0 25px 9px 0;
          color: #979797;
          font-size: 14px;
        }
      }
    }
    @media (max-width: $screen-sm) {
      padding: 20px 0 40px;
    }
  }
  .audio-meditations-wrap {
    padding: 70px 0;
    background-color: $white;
    .title-main {
      margin-bottom: 45px;
    }
    .audio-list {
      margin-left: -36px;
    }
    .audio-block {
      margin-left: 36px;
      padding: 15px;
      width: calc(33.3% - 36px);
      background-color: $white;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.14);
      border-radius: 4px;
      .img-block {
        margin: 0 10px 10px 0;
        flex-shrink: 0;
        width: 140px;
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
        @include aspect-ratio(1,1, 'inner-wrap');
      }
      a {
        color: $textDefault;
      }
    }
    .player-block {
      padding-top: 10px;
      background-color: $white;
      .icon {
        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        &.play {
          margin-right: 10px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          color: $white;
          background-color: $textDefault;
          font-size: 34px;
          &:before {
            content: "\e915";
          }
          &.active {
            &:before {
              content: "\e914";
            }
          }
        }
        &.sound {
          flex-shrink: 0;
          margin: -5px 0 0 14px;
          color: $textDefault;
          font-size: 16px;
          width: auto;
          height: auto;
          background-color: transparent;
          &:before {
            content: "\e91d";
          }
          &.active {
            &:before {
              content: "\e913";
            }
          }
        }
      }
      .info-block {
        .title {
          font-weight: bold;
        }
        .date {
          margin-bottom: 60px;
          font-size: 13px;
          color: #798795;
        }
      }
      .progress-wrap {
        margin-bottom: 3px;
        width: calc(100% - 26px);
        .date {
          font-size: 11px;
          color: #798795;
        }
      }
      .progress-block {
        position: relative;
        height: 6px;
        width: 100%;
        background: #E8E8ED;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.09);
        border-radius: 26px;
        .line {
          left: 0;
          height: 100%;
          background: #3FCBFF;
          border-radius: 26px;
        }
      }
    }
    .control-block {
      .control {
        cursor: pointer;
      }
      @include control-block(-76px);
    }
    @media (max-width: $screen-sm) {
      padding: 30px 0 36px;
      .audio-list {
        margin-left: 0;
      }
      .audio-block {
        margin: 0 0 10px 0;
        width: 100%;
        .img-block {
          width: 100%;
          @include aspect-ratio(16, 5, 'inner-wrap');
        }
      }
      .player-block {
        width: 100%;
        .info-block {
          flex-grow: 1;
          .date {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  .predictions-page-wrap {
    background-color: $white;
    padding: 0 0 56px;
    .text-main {
      margin-bottom: 53px;
      font-size: 15px;
    }
    @media (max-width: $screen-sm) {
      .text-main {
        margin-bottom: 30px;
      }
      .custom-select-block {
        margin-bottom: 15px;
      }
    }
  }
  .experts-block-wrap {
    padding: 56px 0 30px;
    background-color: #1C1C25;
    .title-main {
      color: $white;
    }
  }
}
