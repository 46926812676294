.modal-full {
  max-width: 735px;
  width: 100vw;
  @media (max-width: $screen-sm) {
    margin: 0;
    height: 100vh;
    border: none;
  }
  &.big {
    max-width: 910px;
  }
  &.medium {
    max-width: 535px;
  }
  .modal-content {
    height: 100%;
    border: none;
    border-radius: 0;
  }
  &.modal-crystal {
    width: 390px;
    @media (max-width: $screen-sm) {
      width: 100%;
    }
  }
}

.modal-full-new {

}

.modal-default {
  position: relative;
  .close {
    position: absolute;
    top: 33px;
    right: 33px;
    width: 32px;
    height: 32px;
    background: url('/static/img/svg/close.svg') center no-repeat;
    cursor: pointer;
  }
  .title-main {
    margin-bottom: 24px;
    font-family: 'Alegreya SC', sans-serif;
    font-size: 28px;
    font-weight: bold;
    color: $textDefault;
  }
  .modal-content {
    border: none;
    border-radius: 0;
  }
  @media (max-width: $screen-sm) {
    .close {
      top: 18px;
      right: 18px;
    }
  }
}

.modal-service {
  color: #626469;
  .img-block {
    flex-shrink: 0;
    img {
      display: block;
    }
  }
  .title-main {
    max-width: 300px;
  }
  .content-block {
    padding: 28px 24px;
  }
  .text {
    margin-bottom: 15px;
    font-size: 16px;
  }
  .btn {
    margin-bottom: 16px;
  }
  @media (max-width: $screen-sm) {
    .img-block {
      img {
        width: 100%;
      }
    }
    .content-block {
      padding: 12px 16px 40px;
      background-color: #F5F5FA;
    }
    .title-main {
      margin-bottom: 18px;
      font-size: 24px;
    }
    .text,
    .cost-text {
      font-size: 14px;
    }
    .btn {
      margin-bottom: 12px;
    }
  }
}

.modal-payment {
  .modal-content {
    height: 100%;
    padding: 30px 40px;
  }
  .title-main {
    margin-bottom: 40px;
  }
  .text {
    margin-bottom: 26px;
  }
}

.header-mobile-wrap {
  .head-block {
    position: relative;
    padding: 18px 16px;
    font-size: 14px;
    color:  #626469;
    background-color: #F5F5FF;
    .user-block {
      margin-right: 10px;
      flex-shrink: 0;
      width: 23px;
      height: 23px;
      background: url('/static/img/svg/user.svg') center / cover no-repeat;
    }
    .close {
      position: absolute;
      top: 15px;
      right: 16px;
      width: 30px;
      height: 30px;
      background: url('/static/img/svg/close.svg') center / contain no-repeat;
      opacity: 1;
      cursor: pointer;
      &:before {}
      &:hover {
        opacity: 1;
      }
    }
  }
  .search-block {
    position: relative;
    margin: 18px 16px 0;
    .search {
      position: absolute;
      top: 10px;
      left: 12px;
      font-size: 20px;
      color: #8F9399;
    }
    input {
      width: 100%;
      padding: 9px 16px 9px 40px;
      border: 1px solid #D4D9DE;
      border-radius: 8px;
    }
  }
  .menu-list {
    a {
      padding: 14px 16px;
      font-size: 16px;
      color: #63656A;
      border-bottom: 1px solid #E8E8ED;
      text-decoration: none;
      &:last-child {
        border-bottom: none;
      }
    }
    .icon {
      font-size: 12px;
      color: $textDefault;
    }
  }
  @media (max-width: $screen-sm) {
    height: 100%;
    border: none;
  }
}

.modal-card-desc {
  padding: 22px 16px 36px;
  overflow-y: auto;
  .head-block {
    margin-bottom: 20px;
    img {
      margin-right: 15px;
      flex-shrink: 0;
      max-width: 62px;
    }
    .title-main {
      padding-top: 5px;
      font-size: 18px;
    }
  }
  .text-block {
    color: #626469;
  }
  .pos {
    color: #979797
  }
  .btn {

  }
}

.modal-auth {
  .content-block {
    padding: 20px 38px 28px;
  }
  .title-main {
    margin-bottom: 126px;
  }
  .text {
    margin-bottom: 82px;
    &.small {
      margin-bottom: 0;
      font-size: 14px;
      color: #626469;
    }
  }
  @media (max-width: $screen-sm) {
    .content-block {
      padding: 16px 18px;
    }
    .title-main {
      margin-bottom: 40px;
    }
    .text {
      margin-bottom: 26px;
    }
  }
}

.modal-dragon {
  .content-block {
    padding: 30px 40px;
  }
  .title-main {
    margin-bottom: 40px;
  }
  .text {
    margin-bottom: 26px;
  }
  .dragon {
    position: absolute;
    right: 28px;
    bottom: 4px;
    width: 110px;
    height: 124px;
    background: url('/static/img/dragon3.png') center / cover no-repeat;
  }
  @media (max-width: $screen-sm) {
    .content-block {
      padding: 16px 18px;
    }
    .title-main {
      margin-bottom: 45px;
      font-size: 24px;
    }
    .dragon {
      right: 3px;
      bottom: 3px;
    }
  }
}

.modal-stream-question {
  .content-block {
    padding: 16px 18px;
  }
  .title-main {
    margin: 0 60px 40px 0;
  }
  .text {
    margin-bottom: 26px;
  }
  textarea {
    height: 128px;
    resize: none;
  }
  a {
    color: $textDefault;
  }
}

.modal-profile-table {
  .modal-content {
    padding: 16px 16px 0;
  }
  .title-main {
    margin-right: 35px;
    padding: 0 16px;
    font-family: 'Alegreya SC', sans-serif;
    font-size: 24px;
    font-weight: bold;
  }
  .table {
    margin-bottom: 16px;
  }
  @media (max-width: $screen-sm) {
    .modal-content {
      padding: 16px 0 0;
    }
  }
}

.modal-payment-history {
  .title-main {
    padding: 16px 16px 0;
  }
  .tab-content-list {
    padding: 20px 16px;
  }
  .balance-block {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #D8D8D8;
    font-size: 14px;
    .sum {
      font-size: 16px;
    }
    .crystal {
      width: 13px;
      height: 13px;
      background: url('/static/img/svg/mineral-black.svg') center / cover no-repeat;
    }
    .code {
      color: $black;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.modal-note {
  .title-main {
    margin-bottom: 26px;
  }
  .content-block {
    padding: 16px;
  }
  .custom-input-block {
    margin-bottom: 26px;
  }
  a {
    color: $textDefault;
  }
}

.modal-pred {
  .title-main {
    margin-right: 32px;
  }
  .content-block {
    padding: 30px 40px;
  }
  .custom-input-block {
    margin-bottom: 26px;
  }
  .close {
    width: 24px;
    height: 24px;
  }
  a {
    color: $textDefault;
  }
  @media(max-width: $screen-sm) {
    .close {
      top: 20px;
      right: 22px;
    }
    .content-block {
      padding: 16px 18px;
    }
    .title-main {
      font-size: 24px;
    }
  }
}

.modal-buying-form {
  .modal-content {
    padding: 30px 36px 40px;
  }
  .title-main {
    margin: 0 40px 22px 0;
    font-size: 28px;
    line-height: 36px;
  }
  .content-block {
    overflow-y: auto;
  }
  .img-block {
    margin-right: 18px;
    width: 165px;
    background-position: center;
    background-size: 100% 100%;
    @include aspect-ratio(16, 13.5, 'inner-wrap');
  }
  .info-block {
    padding-top: 12px;
  }
  a {
    color: $textDefault;
  }
  .sum {
    margin: 0 5px;
  }
  .crystal {
    width: 13px;
    height: 13px;
    background: url('/static/img/svg/mineral-black.svg') center no-repeat;
  }
  .flex-row {
    display: flex;
    justify-content: space-between;
    .custom-input-block {
      margin-bottom: 20px;
      width: calc(50% - 9px);
    }
  }
  .small {
    max-width: 310px;
    font-size: 14px;
    color: $gray400;
  }
  .mandala-form {
    .custom-input-block {
      max-width: 330px;
    }
  }
  @media (max-width: $screen-sm) {
    .modal-content {
      padding: 16px 16px 30px;
    }
    .title-main {
      margin: 0 40px 18px 0;
      font-size: 24px;
    }
    .img-block {
      margin: 0 0 40px 0;
      width: 100%;
      @include aspect-ratio(16, 7.56, 'inner-wrap');
    }
    .flex-row {
      display: flex;
      flex-flow: column wrap;
      .custom-input-block {
        margin-bottom: 20px;
        width: 100%;
      }
    }
    .info-block {
      padding-top: 0;
    }
    .mandala-form {
      .custom-input-block {
        max-width: inherit;
      }
    }
  }
}

.modal-zodiac-forecast {
  .modal-content {
    padding: 24px 16px;
  }
  .title-main {
    margin: 0 40px 18px 0;
    font-size: 24px;
  }
  .info-block {
    padding-top: 20px;
    font-size: 18px;
    .small {
      font-size: 13px;
    }
  }
  .card-block {
    margin-right: 25px;
    width: 112px;
    flex-shrink: 0;
    background-size: 100% 100%;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    @include aspect-ratio(9, 13.5, 'inner-wrap');
  }
  .content-block {
    overflow-y: auto;
  }
  .desc-block {
    font-size: 14px;
    .text {
      margin-bottom: 25px;
    }
  }
  .btn-block {
    margin-bottom: 24px;
    a {
      color: $textDefault;
    }
  }
}

.modal-catch-crystal,
.modal-catch-dragon {
  .title-main {
    margin-right: 32px;
    font-size: 28px;
  }
  .crystal {
    display: inline-block;
    vertical-align: top;
    width: 13px;
    height: 13px;
    background: url(/static/img/svg/mineral-black.svg) 50%/cover no-repeat;
  }
  .modal-content {
    padding: 30px 40px 165px;
    overflow-y: auto;
    background: $white url('/static/img/catch-dragon.png') center calc(100% - 50px)  no-repeat;
    &.hide {
      padding-bottom: 72px;
      background-image: none;
    }
  }
  .text {
    font-size: 16px;
  }
  .dragons-list {
    margin-left: -7px;
  }
  .img-wrap {
    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
    }
  }
  .dragon-block {
    margin: 0 0 7px 7px;
    width: calc(33.3% - 7px);
    aspect-ratio: 9/12.3;
    border-radius: 8px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.14);
    background-color: $white;
    background-repeat: no-repeat;
    background-position: center;
  }
  @media (max-width: $screen-sm) {
    .title-main {
      font-size: 24px;
    }
    .modal-content {
      padding: 18px 16px 16px;
      background-image: none;
    }
    .text {
      font-size: 13px;
    }
  }
}

.modal-catch-crystal {
  .modal-content {
    padding: 30px 40px;
    background-image: none;
  }
}

.modal-astro-read {
  max-width: 925px;
  height: 370px;
  .modal-content {
    height: 100%;
  }
  .img-block {
    position: relative;
    flex-shrink: 0;
    width: 375px;
    height: 100%;
    background-size: contain;
    background-position: center;
    .btn {
      position: absolute;
      right: 18px;
      bottom: 18px;
      padding: 7px 18px;
    }
  }
  .info-block {
    padding: 32px 74px 32px 36px;
    height: 100%;
  }
  .toggle-block {
    .text {
      font-size: 18px;
      height: 122px;
    }
  }
  .title {
    margin-bottom: 8px;
    font-size: 20px;
  }
  .author {
    margin-bottom: 8px;
    font-size: 16px;
    color: #979797;
  }
  .text-block {
    padding-right: 10px;
    max-height: 72%;
    overflow: hidden;
    overflow-y: auto;
    @include scrollbar(auto, 7px, #DADADA, #626469);
  }

  @media (max-width: $screen-sm) {
    position: fixed;
    margin: 0;
    height: 100vh;
    border: none;
    .img-block {
      margin-bottom: 0;
      width: 100%;
      height: 30%;
      background-size: contain;
      background-position: center;
      .btn {
        margin-right: -70px;
        right: 50%;
      }
    }
    .info-block {
      padding: 20px 16px 40px;
    }
    .title,
    .author {
      margin-bottom: 18px;
    }
    .text-block {
      height: calc(70% - 84px);
    }
  }
}

.modal-auth-new {
  .modal-content {
    padding: 20px;
    background-color: $white;
    &:before {
      content:'';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: .2;
      background: url('/static/img/modal-bg-new.png') center / 100% auto no-repeat;
    }
  }
  .close {
    top: 25px;
    right: 25px;
    width: 15px;
    height: 15px;
    background-size: 100% 100%;
    z-index: 1;
  }
  .title-small {
    font-size: 26px;
    line-height: 60px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .title-main {
    margin-bottom: 36px;
    font-family: 'Alegreya SC', sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 36px;
    line-height: 60px;
  }
  ul {
    margin-bottom: 26px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .text {
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .btn {
    margin-bottom: 40px;
    min-width: 225px;
  }
  .info {
    margin: 0 auto;
    max-width: 775px;
    opacity: .5;
    color: $black;
    text-transform: uppercase;
    font-size: 10px;
    a {
      color: $black;
    }
  }
  @media (max-width: $screen-sm) {
    .modal-content {
      padding: 12px;
      &:before {
        background: url('/static/img/modal-bg-new.png') center / auto 100% no-repeat;
      }
    }
    .title-small {
      font-size: 16px;
      line-height: normal;
    }
    .title-main {
      margin-bottom: 20px;
      font-size: 24px;
      line-height: normal;
    }
    ul {
      margin-bottom: 48px;
      font-size: 12px;
    }
    .text {
      font-size: 12px;
    }
  }
}
