.taro-page-wrap {
  .title-main {
    font-family: 'Alegreya SC', sans-serif;
    font-size: 32px;
    font-weight: 500;
    color: $textDefault;
    @media (max-width: $screen-sm) {
      font-size: 28px;
    }
  }
  .taro-block-wrap {
    padding: 40px 0;
    color: #626469;
    .title-main {
      margin-bottom: 18px;
    }
    .text-main {
      font-size: 18px;
      margin-bottom: 20px;
    }
    .title {
      margin-bottom: 18px;
      font-family: 'Alegreya SC', sans-serif;
      font-size: 22px;
      font-weight: bold;
      color: $textDefault;
    }
    .taro-img {
      display: block;
      margin-bottom: 30px;
      max-width: 45%;
      &.first {
        margin-right: 15px;
      }
    }
    .text {
      font-size: 18px;
      color: #626469;
    }
    .tab-list,
    .list-group {
      margin-bottom: 20px;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      .tab {
        margin-right: 32px;
        padding: 0 3px 17px;
        text-align: left;
        font-size: 15px;
        flex-grow: 0;
        flex-shrink: 0;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .card-list {
      overflow-x: auto;
      @include scrollbar(auto, 7px, #DADADA, #626469);
    }
    .card-block {
      margin-right: 32px;
      flex-shrink: 0;
      position: relative;
      width: calc(16.66% - 32px);
      font-size: 18px;
      color: $textDefault;
      &.active {
        .img-block {
          border-color: $black;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      .popover-link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .img-block {
        margin-bottom: 14px;
        @include aspect-ratio(9, 13.5, 'inner-wrap');
        background-size: 100% 100%;
        border: 2px solid transparent;
      }
    }
    .scroll-tip {
      font-size: 14px;
      color: #8F9399;
      img {
        margin-left: 12px;
        display: block;
        flex-shrink: 0;
      }
    }
    .cards-collections-wrap {
      padding-bottom: 45px;
      &.raider {
        .toggle-block {
          .text {
            overflow: hidden;
            height: 370px;
          }
        }
      }
    }
    @media (max-width: $screen-sm) {
      padding-top: 0;
      .mob-head-title {
        margin: 0 0 23px;
        padding: 13px 16px;
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        background-color: #2f333b;
      }
      .tab-list,
      .list-group {
        margin: 0 -16px 20px;
        .tab {
          padding: 0 16px 17px;
          margin-right: 0;
        }
      }
      .text {
        font-size: 14px;
      }
      .taro-img {
        max-width: inherit;
        width: 100%;
      }
      .toggle-link-default {
        font-size: 16px;
        .text {
          font-size: 16px;
          color: $textDefault;
        }
      }
      .card-block {
        margin-right: 11px;
        flex-shrink: 0;
        width: calc(31.3% - 11px);
      }
    }
  }
  .card-reveal-block {
    .btn-block {
      max-width: 230px;
    }
    a {
      color: $textDefault;
    }
  }
  .card-of-the-day-wrap {
    padding: 50px 0 55px;
    color: #626469;
    .title-main {
      margin-bottom: 30px;
    }
    .card-img-block {
      flex-flow: row wrap;
      @media (max-width: 365px) {
        flex-flow: column nowrap;
      }
    }
    .img-block {
      margin-right: 85px;
      flex-shrink: 0;
      width: 200px;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      @include aspect-ratio(14, 9, 'inner-wrap');
      &.vertical {
        @include aspect-ratio(9, 14, 'inner-wrap');
      }
    }
    .card-name {
      margin-bottom: 40px;
      font-size: 18px;
      color: $textDefault;
    }
    .desc {
      margin-bottom: 5px;
      color: #979797;
    }
    @media (max-width: $screen-sm) {
      padding: 30px 0;
      .img-block {
        margin-right: 20px;
      }
      .card-name {
        margin-top: 16px;
        font-size: 18px;
      }
      .text {
        font-size: 16px;
      }
      .toggle-block {
        .text {
          height: 148px;
          overflow: hidden;
        }
        &.show {
          .text {
            height: auto;
          }
        }
      }
      .desc {
        margin-bottom: 18px;
      }
    }
    &.metaphor {
      .img-block {
        flex-grow: 1;
        max-width: 745px;
        width: 100%;
        &.vertical {
          max-width: 310px;
        }
      }
      .info-block {

      }
    }
  }
  .card-interpretation-wrap {
    padding: 70px 0 45px;
    .title-main {
      margin-bottom: 18px;
    }
    .text-main {
      font-size: 18px;
    }
    @media (max-width: $screen-sm) {
      padding: 30px 0;
      .text-main {
        font-size: 14px;
      }
    }
  }
  .card-question-block {
    color: $textDefault;
    img {
      display: block;
      max-width: 100%;
    }
    .title {
      font-size: 20px;
      color: $textDefault;
      font-weight: normal;
    }
    .gray {
      color: #979797;
    }
    .info-block {
      padding-top: 30px;
      margin-right: 96px;
    }
    .text {
      &.big {
        font-size: 20px;
      }
    }
    .card-reveal {
      .info-block {
        padding-top: 0;
        margin-right: 0;
      }
      .text {
        font-size: 16px;
      }
      a {
        color: $textDefault;
      }
    }
    @media (max-width: $screen-sm) {
      .card-reveal {
        img {
          margin: 0 20px 20px 0;
          max-width: 118px;
        }
      }
      .title {
        margin-top: 16px;
        font-size: 18px;
      }
      .info-block {
        width: 100%;
        max-width: inherit;
      }
    }
  }
  .interesting-facts-block {
    padding: 50px 0;
    .title-main {
      margin-bottom: 20px;
    }
    .img-block {
      margin-right: 36px;
      flex-shrink: 0;
      max-width: 445px;
      width: 100%;
      @include aspect-ratio(16, 9, 'inner-wrap');
    }
    .toggle-block {
      font-size: 18px;
      .text {
        height: 148px;
      }
      a {
        .text {
          color: $textDefault;
        }
      }
    }
    .control {
      cursor: pointer;
    }
    .control-block {
      @include control-block(-52px);
    }
    @media (max-width: $screen-sm) {
      padding: 30px 0 15px;
      .img-block {
        margin: 0 0 20px;
        max-width: inherit;
      }
      .toggle-block {
        font-size: 16px;
      }
    }
  }
  .predictions-page-wrap {
    .text-main {
      font-weight: bold;
      color: #626469;
    }
    .text {
      font-size: 15px;
    }
  }
  @include card-reveal-block();
}
