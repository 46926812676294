header {
  border-bottom: 1px solid #D1DBD6;
}

.logo {
  margin-right: 30px;
  padding: 16px 0;
  img {
    display: block;
    &.black {
      display: none;
    }
  }
}

.menu-desktop {
  font-size: 18px;
  a {
    position: relative;
    padding: 25px 0;
    margin-right: 30px;
    color: $gray2;
    text-decoration: none;
    &:last-child {
      margin-right: 0;
    }
    &.active,
    &:hover {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        height: 1px;
        background-color: $textDefault;
      }
    }
  }
}

.right-block {
  margin-left: 20px;
  flex-shrink: 0;
}

.auth-block {
  margin-right: 10px;
  color: $gray;
  cursor: pointer;
  text-decoration: none;
  .icon-enter,
  .icon-user {
    margin-right: 10px;
    font-size: 23px;
  }
  .icon-logout {
    font-size: 38px;
  }
  .auth-btn {
    text-decoration: none;
    font-size: 13px;
    line-height: 18px;
    color: $black;
    padding: 1px 18px;
    border: 2px solid $black;
    border-radius: 5px;
    background-color: $white;
  }
}

.icon-menu {
  margin-left: 18px;
  flex-shrink: 0;
  font-size: 18px;
}

@media (max-width: $screen-sm) {
  header {
    min-height: 52px;
  }
  .logo {
    img {
      width: 82px;
    }
  }
}

header.black {
  background-color: $textDefault;
  .logo {
    img {
      display: none;
      &.black {
        display: block;
      }
    }
  }
  .auth-block {
    color: $white;
    .icon-user {
      margin-right: 18px;
      font-size: 27px;
    }
    .auth-btn {
      color: $white;
      padding: 1px 18px;
      border: 2px solid $white;
      border-radius: 5px;
      background-color: transparent;
    }
  }
  .icon-menu {
    color: $white;
  }
}

header.new {
  border-bottom: 1px solid #D1DBD6;
  .auth-block {
    margin-right: 10px;
    text-decoration: none;
    color: $white;
    .icon-enter,
    .icon-user {
      margin-right: 10px;
      font-size: 23px;
    }
    .icon-logout {
      font-size: 38px;
    }
  }
  .menu-desktop {
    padding: 22px 0;
    a {
      padding: 0;
      margin-right: 20px;
      color: $white;
      @include link-line-hover($textDefault);
    }
  }
  .right-block {
    color: $white;
  }

}
