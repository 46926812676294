.numerology-page-wrap {
  .title-main {
    font-family: 'Alegreya SC', sans-serif;
    font-size: 32px;
    font-weight: 500;
    @media (max-width: $screen-sm) {
      font-size: 28px;
    }
  }
  .date-block {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #1f2229;
    height: 62px;
    background: #fff;
    border: 2px solid #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    margin-right: 7px;
    &.active {
      border-color: $textDefault;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .individual-prediction-wrap {
    padding: 40px 0 60px;
    color: $white;
    .title-main {
      margin-bottom: 20px;
    }
    .title {
      margin-bottom: 26px;
      font-size: 22px;
      font-family: 'Alegreya SC', sans-serif;
      font-weight: bold;
    }
    label {
      top: 21px;
      left: 18px;
      color: #798795;
    }
    input {
      margin-bottom: 40px;
      font-size: 16px;
      padding: 29px 18px 9px;
      color: $textDefault;
      border: 1px solid #798795;
      border-radius: 8px;
    }
    .custom-input-block {
      &.active {
        label {
          top: 9px;
        }
      }
    }
    .img-block {
      flex-grow: 1;
      margin-left: 54px;
      flex-shrink: 0;
      max-width: 510px;
      width: 50%;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      @include aspect-ratio(16, 9.5, 'inner-wrap');
    }
    .btn {
      padding: 17px 44px;
    }
    .digit-block {
      font-size: 22px;
    }
    a {
      color: $textDefault;
      &:hover {
        text-decoration: none;
      }
    }
    @media (max-width: $screen-sm) {
      padding: 30px 0 40px;
      background-image: url('/static/img/svg/digits-mobile.svg');
      .title-main {
        margin-bottom: 20px;
      }
      .title {
        margin-bottom: 30px;
        font-size: 18px;
      }
      .left-block {
        flex-grow: 1;
      }
      .btn {
        padding: 11px 34px;
      }
    }
  }
  .numerology-predictions-wrap {
    position: relative;
    padding: 60px 0 80px;
    color: $white;
    z-index: 1;
    .title-main {
      margin-bottom: 18px;
    }
    .container-fluid {
      position: relative;
    }
    .card-block {
      margin-right: 75px;
      flex-shrink: 0;
      width: 178px;
      background-size: 100% 100%;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.14);
      @include aspect-ratio(9, 13.2, 'inner-wrap');
    }
    .toggle-block-default {
      .text {
        height: 95px;
      }
    }
    .date-list {
      margin-bottom: 18px;
    }
    .date-tab-list {
      .date-tab {
        padding-top: 28px;
        display: none;
        &.active {
          display: block;
        }
      }
    }
    .date-list {
      border-bottom: 1px solid #D1DBD6;
      .date-block {
        position: relative;
        padding: 0 16px 24px;
        height: auto;
        color: #8F9399;
        border: none;
        cursor: pointer;
        background-color: transparent;
        &.active {
          color: $white;
          &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -2px;
            width: 100%;
            height: 2px;
            background-color: $white;
          }
        }
      }
    }
    .toggle-block-default {
      font-size: 18px;
      .btn-toggle {
        .icon,
        .text {
          color: $white;
        }
      }
    }
    @media (max-width: $screen-sm) {
      padding: 20px 0 40px;
      overflow: hidden;
      .bg-block {
        max-height: inherit;
        height: 100%;
        background: url(/static/img/svg/forecast-bg2.svg) center bottom / 100% auto no-repeat;
      }
      .card-block {
        margin: 0 auto;
      }
    }
  }
  .number-of-the-day-wrap {
    padding: 50px 0 60px;
    position: relative;
    .container-fluid {
      position: relative;
    }
    .title-main {
      margin-bottom: 30px;
    }
    .bg-block {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #F5F5FA;
    }
    .card-block {
      margin-right: 75px;
      flex-shrink: 0;
      width: 178px;
      background-size: 100% 100%;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.14);
      @include aspect-ratio(9, 13.2, 'inner-wrap');
    }
    @media (max-width: $screen-sm) {
      .card-block {
        margin: 0 auto 18px;
      }
      .toggle-block-default {
        .text {
          height: 85px;
        }
      }
      .bg-block {
        margin-top: -198px;
        height: 123%;
        background: url('/static/img/svg/number-bg.svg') center bottom / 100% auto no-repeat;
      }
    }
  }
  .major-month-dates-wrap {
    background-color: $white;
    padding: 30px 0;
    .title-main {
      margin-bottom: 20px;
    }
    .tab-list {
      padding: 5px 0;
      margin-bottom: 25px;
      border: none;
      overflow-x: auto;
      .tab.active:after {
        display: none;
      }
    }
    .date-block {
      flex-shrink: 0;
      flex-grow: 0;
      min-width: 110px;
    }
    .img-block {
      margin-left: 50px;
      max-width: 50%;
      flex-shrink: 0;
      &.one {
        width: 445px;
        height: 220px;
        background: url('/static/img/major-date1.png') center / contain no-repeat;
      }
      &.two {
        width: 667px;
        height: 593px;
        background: url('/static/img/major-date2.png') center / contain no-repeat;
      }
      &.three {
        width: 622px;
        height: 415px;
        background: url('/static/img/major-date3.png') center / contain no-repeat;
      }
    }
    .tab-content {
      position: relative;
      color: #626469;
    }
    @media (max-width: $screen-sm) {
      .title-main {
        margin-bottom: 18px;
      }
      .text-block {
        position: relative;
        z-index: 1;
        .text {
          height: 154px;
        }
        &.show {
          .text {
            height: auto;
          }
        }
      }
      .img-block {
        margin: 0;
        max-width: inherit;
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
        opacity: .1;
      }
    }
  }
  .individual-predictions-wrap {
    background-color: $white;
    padding: 30px 0 55px;
    .title-main {
      margin-bottom: 18px;
    }
    .custom-input-block,
    .custom-select-block {
      width: calc(50% - 18px);
    }
    .text {
      color: #626469;
    }
    .card-list {
      margin-bottom: 10px;
      margin-left: -5px;
    }
    .card-block {
      margin: 0 0 5px 5px;
      width: 113px;
      background-size: 100% 100%;
      @include aspect-ratio(1, 1, 'inner-wrap');
      &.active {
        background-color: $white;
        border-radius: 4px;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.14);
      }
    }
    .btn-block {
      a {
        color: $textDefault;
      }
    }
    .numerology-text-block {
      font-size: 14px;
    }
    @media (max-width: $screen-sm) {
      padding: 28px 0;
      .custom-input-block,
      .custom-select-block {
        width: 100%;
      }
      .card-block {
        max-width: 93px;
        width: calc(33.3% - 5px);
      }
    }
  }
  .experts-block-wrap {
    padding: 56px 0 30px;
    background-color: #1C1C25;
    .title-main {
      color: $white;
    }
  }
}
