@mixin text-overflow($width: inherit) {
  max-width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin flex($flow: nowrap, $justify: flex-start, $align: stretch) {
  display: flex;
  flex-flow: row $flow;
  justify-content: $justify;
  align-items: $align;
}

@mixin aspect-ratio($width, $height, $name) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .#{$name} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin position-centered() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin smooth-scroll-x() {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}


@mixin link-line($color){
  display: inline-block;
  text-decoration: none;
  &:after{
    content:'';
    display: block;
    width: 100%;
    height: 1px;
    background-color: $color;
  }
  &:hover{
    &:after{
      background-color: transparent;
    }
  }
}

@mixin link-line-hover($color) {
  display: inline-block;
  text-decoration: none;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: transparent;
  }
  &:hover {
    &:after {
      background-color: $color;
    }
  }
}

@mixin all-cover-link() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


@mixin placeholder($color: #000){
  &::-webkit-input-placeholder{
    color: $color;
  }
  &::-moz-placeholder{
    color: $color;
  }
  &:-ms-input-placeholder{
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
}

@mixin scrollbar($width: auto, $height: 5px, $color1: #D8D8D8, $color2: $blue) {
  &::-webkit-scrollbar {
    width: $width;
    height: $height;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: $color1;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: $color2;
  }
}

@mixin h1($fontSize: 53px, $color: $black, $fontWeight: bold) {
  margin-top: 0;
  font-weight: $fontWeight;
  font-size: $fontSize;
  color: $color;
  @media (max-width: $screen-sm) {
    font-size: 22px;
  }
}

@mixin h2($fontSize: 35px, $color: $black, $fontWeight: bold) {
  margin-top: 0;
  font-size: $fontSize;
  font-weight: $fontWeight;
  color: $color;
  @media (max-width: $screen-sm) {
    font-size:  22px;
  }
}

@mixin control-block($top: -64px) {
  position: absolute;
  top: $top;
  right: 0;
  width: 220px;
  @media (max-width: $screen-sm) {
    position: static;
    top: auto;
    right: auto;
    width: auto;
  }
  .control {
    cursor: pointer;
  }
}
@mixin card-reveal-block() {
  .card-reveal-block {
    img {
      margin-right: 53px;
      max-width: 100%;
    }
    .info-block {
      min-width: 210px;
      width: 100%;
      .title {
        margin-bottom: 18px;
        font-size: 18px;
        font-weight: normal;
        color: #979797;
      }
      .text {
        margin-bottom: 30px;
        font-size: 15px;
      }
    }
    @media (max-width: $screen-sm) {
      img {
        margin: 0 auto 18px;
        max-width: 100%;
      }
      .desc-block {
        padding: 0;
        font-size: 14px;
      }
    }
  }
}
