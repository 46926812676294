.human-design-page-wrap {
  .title-main {
    font-size: 32px;
    font-weight: 500;
    font-family: 'Alegreya SC', sans-serif;
    @media (max-width: $screen-sm) {
      font-size: 28px;
    }
  }
  .human-design-block-wrap {
    padding: 40px 0 48px;
    .title-main {
      margin-bottom: 35px;
    }
    .img-block {
      margin-right: 98px;
      flex-shrink: 0;
      width: 277px;
      height: 277px;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
    .text {
      font-size: 18px;

    }
    .toggle-block {
      .text {
        color: #626469;
        height: 120px;
        overflow: hidden;
      }
      .btn-toggle {
        color: $textDefault;
        font-size: 16px;
      }
      &.show {
        .text {
          height: auto;
        }
      }
    }
    .toggle-link-default {
      .text {
        color: $textDefault;
        font-size: 16px;
      }
    }
    @media (max-width: $screen-sm) {
      .title-main {
        margin-bottom: 15px;
        font-size: 28px;
      }
      .img-block {
        margin-bottom: 18px;
        width: 138px;
        height: 138px;
      }
      .toggle-block {
        .text {
          font-size: 14px;
          height: 116px;
        }
      }
    }
  }
  .rave-block {
    .btn-dark {
      display: none;
      &.collapsed {
        display: inline-block;
      }
    }
    img {
      display: block;
      max-width: 100%;
    }
  }
  .facts-block-wrap {
    padding: 50px 0;
    .title-main {
      margin-bottom: 30px;
    }
    .img-block {
      margin-right: 36px;
      width: 445px;
      background-size: cover;
      flex-shrink: 0;
      @include aspect-ratio(16, 9, 'inner-wrap');
    }
    .text {
      font-size: 18px;
      color: #626469;
    }
    .control-block {
      @include control-block();
    }
    .control {
      font-weight: bold;
      &.left {
        .icon {
          margin-right: 12px;
        }
      }
      &.right {
        .icon {
          margin-left: 12px;
        }
      }
      .icon {
        font-size: 20px;
      }
    }
    .toggle-block {
      .text {
        height: 120px;
        overflow: hidden;
      }
    }
    @media (max-width: $screen-sm) {
      .img-block {
        margin: 0 0 18px 0;
        width: 100%;
        @include aspect-ratio(16, 7, 'inner-wrap');
      }
      .text {
        height: 85px;
        overflow: hidden;
        font-size: 16px;
      }
      .toggle-block.show {
        .text {
          height: auto;
        }
      }
    }
  }
  .body-graph-people-wrap {
    padding: 70px 0;
    .title-main {
      margin-bottom: 20px;
    }
    .graph-list {
      margin-left: -35px;
    }
    .body-graph-block {
      width: calc(33.3% - 35px);
      margin-left: 35px;
      cursor: pointer;
      &.active {
        .img-block {
          background-size: 102% 102%;
          box-shadow: 0 1px 8px rgba(0, 0, 0, 0.14);
          border-radius: 4px;
        }
        .title-block {
          display: none !important;
        }
        .player-block {
          display: block;
        }
      }
      .img-block {
        margin-bottom: 15px;
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        @include aspect-ratio(16, 13.2, 'inner-wrap');
      }
      .player-block {
        display: none;
        padding: 34px 14px 22px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $white;
        .icon {
          font-family: 'icomoon' !important;
          speak: never;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;

          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          &.play {
            margin-right: 16px;
            flex-shrink: 0;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            color: $white;
            background-color: $textDefault;
            font-size: 34px;
            &:before {
              content: "\e915";
            }
            &.active {
              &:before {
                content: "\e914";
              }
            }
          }
          &.sound {
            flex-shrink: 0;
            margin: -5px 0 0 14px;
            color: $textDefault;
            font-size: 16px;
            width: auto;
            height: auto;
            background-color: transparent;
            &:before {
              content: "\e91d";
            }
            &.active {
              &:before {
                content: "\e913";
              }
            }
          }
        }
        .progress-wrap {
          margin-bottom: 3px;
          width: calc(100% - 26px);
          .date {
            font-size: 11px;
            color: #798795;
          }
        }
        .progress-block {
          position: relative;
          height: 6px;
          width: 100%;
          background: #E8E8ED;
          box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.09);
          border-radius: 26px;
          .line {
            left: 0;
            height: 100%;
            background: #3FCBFF;
            border-radius: 26px;
          }
        }
      }
      .title {
        font-weight: bold;
      }
      .name {
        font-size: 16px;
        color: $black;
      }
      .date {
        font-size: 13px;
        color: #798795;
      }
      .icon {
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        color: $white;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $textDefault;
      }
    }
    .control-block {
      @include control-block(-47px);
    }
    .control {
      font-weight: bold;
      &.left {
        .icon {
          margin-right: 12px;
        }
      }
      &.right {
        .icon {
          margin-left: 12px;
        }
      }
      .icon {
        font-size: 20px;
      }
    }
    @media (max-width: $screen-sm) {
      padding: 30px 0;
      .title-main {
        margin-bottom: 18px;
        font-size: 28px;
      }
      .graph-list {
        margin-left: 0;
      }
      .body-graph-block {
        margin-left: 0;
        width: 100%;
      }
    }
  }
  .service-consult-wrap {
    padding: 60px 0 55px;
    .tab-list {
      margin-bottom: 20px;
      position: relative;
      .tab {
        margin-right: 28px;
        flex-grow: 0;
        padding: 8px 2px 18px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .title-main {
      margin-bottom: 28px;
      font-size: 32px;
      font-weight: 500;
      color: $textDefault;
    }
    .specialist-list {
      margin-left: -36px;
      overflow-y: auto;
    }
    .specialist-block {
      margin-left: 36px;
      width: calc(33.33% - 36px);
      flex-shrink: 0;
      color: $black;
      .img-block {
        position: relative;
        margin-bottom: 15px;
        aspect-ratio: 16/9;
        background-size: 100% auto;
        background-position: center;
        background-repeat: no-repeat;
      }
      .btn {
        position: absolute;
        right: 6px;
        bottom: 18px;
        padding: 9px 20px;
      }
      .date {
        margin-bottom: 10px;
        font-size: 14px;
        color: #8F9399;
      }
      .title {
        margin-bottom: 12px;
        font-weight: normal;
        color: $textDefault;
      }
    }
    @media (max-width: $screen-sm) {
      .title-main {
        font-size: 28px;
        font-weight: 700;
      }
      .tab-list {
        margin: 0 -16px 20px;
        padding: 0 16px;
        .tab {
          margin-left: -16px;
          padding-left: 16px;
          margin-right: 16px;
          padding-right: 16px;
          text-align: left;
          width: 50%;
        }
      }
      .specialist-list {
        margin-left: -20px;
      }
      .specialist-block {
        margin-left: 20px;
        width: calc(100% - 93px);
        .img-block {
          margin-bottom: 15px;
          background-position: center;
          background-size: auto 100%;
          @include aspect-ratio(16, 15, 'inner-wrap');
        }
        .title {
          margin-bottom: 10px;
          font-size: 14px;
        }
      }
    }
  }

  .experts-block-wrap {
    padding: 70px 0 80px;
    .title-main {
      margin-bottom: 20px;
    }
    .experts-list {
      margin-left: -15px;
      overflow-x: auto;
      @include smooth-scroll-x();
    }
    .experts-block {
      margin-left: 15px;
      padding: 30px 14px;
      width: calc(20% - 15px);
      min-width: 200px;
      background: $white;
      box-shadow: 0 1px 8px rgba( 0, 0, 0, 0.14);
      border-radius: 20px;
      .img-block {
        margin: 0 auto 25px;
        flex-shrink: 0;
        width: 126px;
        height: 126px;
        border-radius: 50%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        overflow: hidden;
      }
      .info-block {
        margin-bottom: 16px;
      }
      .expert-name {
        margin-bottom: 6px;
        font-size: 18px;
        font-weight: bold;
      }
      .text {
        font-size: 15px;
        color: #8F9399;
      }
      .top-block {
        flex-grow: 1;
      }
    }
    @media (max-width: $screen-sm) {
      .experts-list {
        margin-left: 0;
        overflow-x: visible;
      }
      .experts-block {
        padding: 20px 10px 5px;
        margin: 0 0 15px 0;
        width: 100%;
        border-radius: 4px;
        .img-block {
          margin: 0;
          width: 85px;
          height: 85px;
        }
        .info-block {
          flex-grow: 1;
        }
        .name {
          font-size: 16px;
        }
        .text {
          font-size: 13px;
        }
      }
    }
  }
}

.iframe-hide-bot {
  position: absolute;
  left: 0;
  right: 0;
  height: 90px;
  background-color: $white;
  bottom: 0;
}
